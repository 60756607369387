import { of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import merge from 'deepmerge';

class MultiTranslateHttpLoader {
    constructor(http, resources) {
        this.http = http;
        this.resources = resources;
    }
    getTranslation(lang) {
        const requests = this.resources.map(resource => {
            const path = resource.prefix + lang + resource.suffix;
            return this.http.get(path).pipe(catchError(res => {
                console.error("Something went wrong for the following translation file:", path);
                console.error(res.message);
                return of({});
            }));
        });
        return forkJoin(requests).pipe(map(response => merge.all(response)));
    }
}

// export * from './lib/ngx-translate-multi-http-loader.module';

/**
 * Generated bundle index. Do not edit.
 */

export { MultiTranslateHttpLoader };

