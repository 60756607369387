<div class="payment-card">
  <div class="loading" *ngIf="isLoading">
    <nz-spin nzSimple [nzSpinning]="isLoading"></nz-spin>
  </div>
  @if(subMerchant$ | async; as subMerchantData){

  <ng-container>
    <div class="row payment-card__header">
      <div class="col-12 content">
        <h4 class="content-website content-title">
          {{ subMerchantData?.webSite }}
        </h4>
        <h1 class="content-amount content-title">
          @if (subMerchantData.recurring) {

          {{
            ((subMerchantData.recurring.amount
              ? subMerchantData.recurring.amount
              : ''
            ) | currency : subMerchantData.currency : 'symbol' : '1.0' : 'it') +
              ' / '
          }}

          @switch ( subMerchantData.recurring.frequency) { @case('daily') {
          {{ 'BKN.SUBSCRIPTION.daily' | translate }}
          } @case('weekly') {
          {{ 'BKN.SUBSCRIPTION.weekly' | translate }}
          } @case('monthly') {
          {{ 'BKN.SUBSCRIPTION.monthly' | translate }}
          } @case('annual') {
          {{ 'BKN.SUBSCRIPTION.annual' | translate }}
          } @default {
          {{ subMerchantData.recurring.frequency }}
          } } }@else{
          {{
            subMerchantData.amount
              | currency : subMerchantData.currency : 'symbol' : '1.2-2' : 'it'
          }}
          }
        </h1>
        <p class="content-order">
          {{ subMerchantData?.reference }}
        </p>
      </div>
    </div>
    <hr />
    <div class="row payment-card__methods">
      <h2>{{ 'BKN.PaymentMethod' | translate }}</h2>
      <div class="col-12 justify-content-between align-items-center">
        <nz-radio-group
          [(ngModel)]="selectedPaymentMethod"
          name="selectedPaymentMethod"
          class="w-100 d-flex gap-2 justify-content-between selectedPaymentMethod"
          (ngModelChange)="handleChangePaymentMethod($event)"
        >
          <label
            *ngFor="let paymentMethod of subMerchantData.paymentGateways"
            nz-radio-button
            [nzValue]="paymentMethod.id"
            [nzDisabled]="paymentMethod.id === 'paypal' && !(lodadedJs | async)"
            class="d-flex"
          >
            <div>
              <p class="m-0">
                {{
                  paymentMethod.name === 'Paypal'
                    ? 'Paypal'
                    : ('BKN.PAYMENT_FORM.Cards' | translate)
                }}
              </p>

              <div
                class="d-flex gap-1 align-items-center"
                *ngIf="paymentMethod.id !== 'paypal'"
              >
                <div>
                  <img class="visa" src="assets/images/visa.svg" />
                </div>
                <div>
                  <img class="mastercard" src="assets/images/mastercard.svg" />
                </div>
                <div>
                  <img class="unionpay" src="assets/images/unionpay.svg" />
                </div>
              </div>

              <div
                class="d-flex gap-1 align-items-center"
                *ngIf="paymentMethod.id === 'paypal'"
              >
                <div>
                  <img src="assets/images/paypal.svg" />
                </div>
              </div>
            </div>
          </label>
        </nz-radio-group>
      </div>
    </div>

    @if (selectedPaymentMethod !== 'paypal') {
    <div class="row payment-card__content">
      <app-bkn-payment-form
        [subMerchant]="subMerchantData"
        (submitEvent)="onSubmit($event)"
        [payment]="payment$ | async"
        [isSpinning]="isLoading$ | async"
      ></app-bkn-payment-form>
    </div>
    }
    <div
      class="row payment-card__controller"
      [hidden]="selectedPaymentMethod !== 'paypal'"
    >
      <div class="col-12 text-center my-2">
        <div id="paypal-button-container"></div>
      </div>
    </div>
  </ng-container>

  <a
    *ngIf="
      (subMerchant$ | async)?.webSite &&
      (subMerchant$ | async)?.channel !== 'Manual'
    "
    class="back-to-merchant m-0"
    [href]="
      (subMerchant$ | async)?.webSite?.includes('http')
        ? (subMerchant$ | async)?.webSite
        : 'http://' + (subMerchant$ | async)?.webSite
    "
    target="_blank"
    >{{ 'HOME.BackTo' | translate }} {{ (subMerchant$ | async)?.webSite }}</a
  >

  }@else {
  <ng-container>
    <div class="loading" *ngIf="true">
      <nz-spin nzSimple [nzSpinning]="true"></nz-spin>
    </div>
  </ng-container>
  }
</div>

<iframe #frame class="d-none" [src]="iframeUrl"></iframe>
