import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SubscriberNew} from '@models/forms/subscription-new-form';
import {Observable, of} from 'rxjs';

import {environment} from '@env/environment';
import {SubscriptionForm} from '@models/index';
import {MultilinguaService} from '@services//multilingua.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(
    private _http: HttpClient,
    private readonly _multiLanguageService: MultilinguaService
  ) {}

  Find(id: string): Observable<SubscriptionForm> {
    return this._http.get<SubscriptionForm>(
      environment.API_URL + `subscriptions/${id}`
    );
  }

  Initialize(
    subscriberId: string,
    externalId?: string,
    cancelUrl?: string,
    returnUrl?: string
  ): Observable<{id: string}> {
    return this._http.post<{id: string}>(
      environment.API_URL + `subscriptions/${subscriberId}/initializations`,
      {
        externalId,
        cancelUrl,
        returnUrl,
      }
    );
  }

  InitializeSubscriber(subscriberId: string): Observable<{id: string}> {
    return this._http.post<{id: string}>(
      environment.API_URL + `subscribers/${subscriberId}/initializations`,
      {}
    );
  }

  AddSubscriber(input: SubscriberNew) {
    const preffered_lang = this._multiLanguageService.getChoosenLang();

    return this._http.post<{id: string; paymentSetupRedirectUrl: string}>(
      environment.API_URL + `subscribers`,
      input,
      {headers: {'x-PreferredLanguage': preffered_lang}}
    );
  }

  GetSubscriptionFromLocal(
    id_subscription: string
  ): Observable<SubscriptionForm | undefined> {
    return localStorage.getItem(`submerchant_sub_${id_subscription}`)
      ? of(
          JSON.parse(
            localStorage.getItem(`submerchant_sub_${id_subscription}`)!
          ) as SubscriptionForm
        )
      : this.Find(id_subscription);
  }
}
