import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, of} from 'rxjs';
import {ROUTES_KEYS} from '../constant';
import {ErrorService} from '../services/error.service';
import {paramsToLower} from '../utils/query-params-utils';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const errorservice = inject(ErrorService);
  const activateRoute = inject(ActivatedRoute);

  return next(req).pipe(
    catchError((error: any) => {
      // if ([401, 403].includes(error.status)) {
      //   console.log('errorInterceptor', error);
      // }
      if (!error.url.includes('submerchant')) {
        errorservice.Set(error);
        activateRoute.root.queryParams.subscribe(item => {
          const res = paramsToLower(item);
          router.navigate([ROUTES_KEYS.ERROR], {
            queryParams: {
              paymentId: res['paymentid'],
              lang: res['lang'],
              livemode: res['livemode'],
              embedRequired: res['embedRequired'],
              embedTheme: res['embedTheme'],
            },
          });
        });
      }
      return of(error);
    })
  );
};
