import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Frame3dsService {
  private _uri: string = '';
  iframeCloseEvent: EventEmitter<void> = new EventEmitter();

  constructor() {}

  setOtpUri(uri: string) {
    this._uri = uri;
  }

  GetOtpUri() {
    return this._uri;
  }
}
