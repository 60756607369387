import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, tap} from 'rxjs';
import {PrimitiveSuccess} from './../models/primitive-vm';

import {environment} from '@env/environment';
import {ResponseLink} from '../models';
import {PaymentDto, SubmerchantDto} from '../models/data-contracts';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private _http: HttpClient) {}

  GetSubMerchant(id_payment: string): Observable<SubmerchantDto> {
    return id_payment
      ? this._http
          .get<SubmerchantDto>(
            environment.API_URL + `Payments/${id_payment}/Submerchant`
          )
          .pipe(
            tap(xx =>
              sessionStorage.setItem(
                `submerchant_${id_payment}`,
                JSON.stringify(xx)
              )
            )
          )
      : of({} as SubmerchantDto);
  }

  Find(id_payment: string): Observable<PaymentDto> {
    return this._http.get<PaymentDto>(
      environment.API_URL + `Payments/${id_payment}`
    );
  }

  PaymentLink(idLink: string): Observable<ResponseLink> {
    return this._http.get<ResponseLink>(
      environment.API_URL + `paymentlinks/${idLink}`
    );
  }

  Validate(id_payment: string): Observable<PrimitiveSuccess<boolean>> {
    return this._http.post<PrimitiveSuccess<boolean>>(
      environment.API_URL + `Payments/${id_payment}/validation`,
      {}
    );
  }

  GetSubMerchantFromSession(
    id_payment: string
  ): Observable<SubmerchantDto | undefined> {
    return sessionStorage.getItem(`submerchant_${id_payment}`)
      ? of(
          JSON.parse(
            sessionStorage.getItem(`submerchant_${id_payment}`)!
          ) as SubmerchantDto
        )
      : this.GetSubMerchant(id_payment);
  }

  GetSubMerchantFromLocal(
    id_payment: string
  ): Observable<SubmerchantDto | undefined> {
    return sessionStorage.getItem(`submerchant_${id_payment}`)
      ? of(
          JSON.parse(
            sessionStorage.getItem(`submerchant_${id_payment}`)!
          ) as SubmerchantDto
        )
      : this.GetSubMerchant(id_payment);
  }

  GetPaymentId() {
    return sessionStorage.getItem('paymentid') || '';
  }

  SetGatewayPaymentId(id: string) {
    sessionStorage.setItem('gatewaypaymentid', id);
  }

  GetGatewayPaymentId() {
    return sessionStorage.getItem('gatewaypaymentid');
  }

  SetCurrency(currency: string) {
    sessionStorage.setItem('currency', currency);
  }

  GetCurrency() {
    return sessionStorage.getItem('currency');
  }

  SetPaymentId(id: string) {
    sessionStorage.removeItem('paymentid');
    sessionStorage.setItem('paymentid', id);
  }

  SetChargeId(currency: string) {
    sessionStorage.setItem('chargeid', currency);
  }

  GetChargeId() {
    return sessionStorage.getItem('chargeid');
  }

  ClearAllSessionsId(id_payment: string) {
    sessionStorage.removeItem(`submerchant_${id_payment}`);
    sessionStorage.removeItem('chargeid');
    sessionStorage.removeItem('paymentid');
    sessionStorage.removeItem('currency');
    sessionStorage.removeItem('gatewaypaymentid');
  }
}
