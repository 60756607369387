import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DateFormat} from '../models/multilingua/dateFormatCostant';
import {TranslateLanguage} from '../models/multilingua/translate-languages.enum';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MultilinguaService {
  private formatDate: string = '';
  private startDateLabel: string = '';
  private endDateLabel: string = '';

  constructor(private translateService: TranslateService) {}

  public static getDefaultLang(): string {
    return TranslateLanguage.EN;
  }

  public switchLanguage(language: string): any {
    this.translateService.use(language);
    localStorage.setItem('lang', language);
    const url = new URL(window.location.href);
    url.searchParams.set('lang', language);
    window.location.href = url.toString();
    // window.location.reload();
  }

  public getRangeDateLabelTranslate(): string[] {
    let listLabel: string[] = [];
    this.translateService
      .get(['PAYMENT.PAYMENT_LINKS.StartDate', 'PAYMENT.PAYMENT_LINKS.EndDate'])
      .pipe(untilDestroyed(this))
      .subscribe(translations => {
        this.startDateLabel = translations['PAYMENT.PAYMENT_LINKS.StartDate'];
        this.endDateLabel = translations['PAYMENT.PAYMENT_LINKS.EndDate'];

        listLabel.push(this.startDateLabel);
        listLabel.push(this.endDateLabel);
      });

    return listLabel;
  }
  public getFormattedDate(locale: string): any {
    switch (locale) {
      case 'it':
        this.formatDate = DateFormat.DateIT; //'dd/MM/yyyy HH:mm:ss';
        break;
      case 'en':
        this.formatDate = DateFormat.DateEN; //'yyyy/MM/dd HH:mm:ss';
        break;
      case 'ka':
        this.formatDate = DateFormat.DateGE; //'yyyy/MM/dd HH:mm:ss';
        break;
      default:
        this.formatDate = DateFormat.NONE;
    }

    return this.formatDate;
  }
  public getValueGenericAsync(value: string): any {
    return this.translateService.get([value]);
  }
  public getValueGeneric(value: string): any {
    let result = this.translateService.instant(value) || '';
    return result;
  }

  public getChoosenLang(): any {
    let result =
      localStorage.getItem('lang') || MultilinguaService.getDefaultLang();
    return result;
  }
}
