import {EventEmitter, Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  no_footer_urls: string[] = ['3ds2', 'thankyou', 'finish'];
  showFoter: EventEmitter<boolean> = new EventEmitter();

  private _history: string = '';
  constructor(private router: Router) {}

  HasFooter(url: string): boolean {
    return !(
      url.includes('3ds2') ||
      url.includes('thankyou') ||
      url.includes('finish')
    );
  }

  Regiter() {
    this.router.events.subscribe(event => {
      if (
        event instanceof NavigationStart &&
        event.url &&
        !event.url.toLocaleLowerCase().includes('login') &&
        !event.url.toLocaleLowerCase().includes('auth') &&
        event.url.toLocaleLowerCase() !== '/'
      ) {
        if (!this._history) this._history = event.url;
      }
    });
  }

  StartUrl() {
    return this._history;
  }
}
