import {createAction, props} from '@ngrx/store';
import {AuthorizeResponseDto} from '../../models';
import {
  AuthorizeBknRequestDto,
  AuthorizeDto,
  AuthorizeRequestDto,
  ChargesDto,
  ChargesRequestDto,
} from '../../models/data-contracts';

export const charge = createAction('[Charges] charge', props<{id: string}>());

export const chargeSuccess = createAction(
  '[Charges] charge Success',
  props<{response: AuthorizeResponseDto}>()
);

export const chargeError = createAction(
  '[Charges] charge Error',
  props<{error: any}>()
);

export const chargeReset = createAction('[Charges] charge Reset');

export const chargesCreate = createAction(
  '[Charges] create',
  props<{charges: ChargesRequestDto}>()
);

export const chargesCreateSuccess = createAction(
  '[Charges] create Success',
  props<{charges: ChargesDto}>()
);

export const chargesCreateError = createAction(
  '[Charges] create Error',
  props<{error: any}>()
);

export const chargesCreateReset = createAction('[Charges] create Reset');

export const authorize = createAction(
  '[Charges] authorize',
  props<{request: AuthorizeRequestDto}>()
);

export const authorizeSuccess = createAction(
  '[Charges] authorize Success',
  props<{authorize: AuthorizeDto}>()
);

export const authorizeError = createAction(
  '[Charges] authorize Error',
  props<any>()
);

export const authorizeReset = createAction('[Charges] authorize Reset');

export const authorizeBkn = createAction(
  '[Charges] authorize bkn',
  props<{request: AuthorizeBknRequestDto}>()
);

export const authorizeBknSuccess = createAction(
  '[Charges] authorize bkn Success',
  props<{authorize: AuthorizeDto}>()
);

export const authorizeBknError = createAction(
  '[Charges] authorize bkn Error',
  props<{error: any}>()
);

export const authorizeBknReset = createAction('[Charges] authorize bkn Reset');

export const loadingAuthorizeBkn = createAction(
  '[Charges] loading authorize bkn',
  props<{isLoading: boolean}>()
);
