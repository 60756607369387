import {EntityAdapter, EntityState, createEntityAdapter} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {SubscriptionForm} from '../../models';
import {SubscriberActions} from '../actions';

export const subscribersFeatureKey = 'subscribers';

export interface State extends EntityState<Subscription> {
  error?: any;
  errorSubscription?: any;
  errorAddsubscriber?: any;
  subscription: SubscriptionForm | undefined;
  paymentId?: string;
  subscriberId?: string;
  added?: {id: string; paymentSetupRedirectUrl: string};
  isLoadingInitialize: boolean;
  isLoadingSubscription: boolean;
  isLoadingAddsubscriber: boolean;
  isChangingPaymentMethod: boolean;
}

export const adapter: EntityAdapter<Subscription> =
  createEntityAdapter<Subscription>({});

export const initialState: State = adapter.getInitialState({
  entities: [],
  error: undefined,
  errorSubscription: undefined,
  paymentId: undefined,
  subscription: undefined,
  added: undefined,
  ids: [],
  isLoadingInitialize: false,
  isLoadingSubscription: false,
  isLoadingAddsubscriber: false,
  isChangingPaymentMethod: false,
});

export const subscribersReducers = createReducer(
  initialState,

  on(SubscriberActions.initialization, (state, {subscriberId}) => {
    return {
      ...state,
      error: undefined,
      paymentId: undefined,
      isLoadingInitialize: true,
      subscriberId: subscriberId,
    };
  }),
  on(SubscriberActions.initializationSuccess, (state, {id}) => ({
    ...state,
    paymentId: id,
    error: undefined,
    isLoadingInitialize: false,
  })),
  on(SubscriberActions.initializationError, (state, {errorMessage}) => ({
    ...state,
    paymentId: undefined,
    error: errorMessage,
    isLoadingInitialize: false,
  })),
  on(SubscriberActions.initializationReset, state => ({
    ...state,
    error: undefined,
    paymentId: undefined,
    isLoadingInitialize: false,
  })),

  on(SubscriberActions.Subcriberinitialization, state => {
    return {
      ...state,
      error: undefined,
      paymentId: undefined,
      isLoadingInitialize: true,
    };
  }),
  on(SubscriberActions.SubcriberinitializationSuccess, (state, {id}) => ({
    ...state,
    paymentId: id,
    error: undefined,
    isLoadingInitialize: false,
  })),
  on(
    SubscriberActions.SubcriberinitializationError,
    (state, {errorMessage}) => ({
      ...state,
      paymentId: undefined,
      error: errorMessage,
      isLoadingInitialize: false,
    })
  ),
  on(SubscriberActions.SubcriberinitializationReset, state => ({
    ...state,
    error: undefined,
    paymentId: undefined,
    isLoadingInitialize: false,
  })),

  on(SubscriberActions.subscription, state => {
    return {
      ...state,
      errorSubscription: undefined,
      subscription: undefined,
      isLoadingSubscription: true,
    };
  }),
  on(SubscriberActions.subscriptionSuccess, (state, {subscription}) => ({
    ...state,
    errorSubscription: undefined,
    subscription,
    isLoadingSubscription: false,
  })),
  on(SubscriberActions.subscriptionError, (state, {errorMessage}) => ({
    ...state,
    subscription: undefined,
    errorSubscription: errorMessage,
    isLoadingSubscription: false,
  })),
  on(SubscriberActions.subscriptionReset, state => ({
    ...state,
    errorSubscription: undefined,
    subscription: undefined,
    isLoadingSubscription: false,
  })),

  on(SubscriberActions.addSubscriber, state => {
    return {
      ...state,
      errorAddsubscriber: undefined,
      added: undefined,
      isLoadingAddsubscriber: true,
    };
  }),
  on(
    SubscriberActions.addSubscriberSuccess,
    (state, {id, paymentSetupRedirectUrl}) => ({
      ...state,
      errorAddsubscriber: undefined,
      added: {id, paymentSetupRedirectUrl},
      isLoadingAddsubscriber: false,
    })
  ),
  on(SubscriberActions.addSubscriberError, (state, {errorMessage}) => ({
    ...state,
    errorAddsubscriber: errorMessage,
    added: undefined,
    isLoadingAddsubscriber: false,
  })),
  on(SubscriberActions.addSubscriberReset, state => ({
    ...state,
    errorAddsubscriber: undefined,
    added: undefined,
    isLoadingAddsubscriber: false,
  })),
  on(SubscriberActions.changePaymentMethodInit, state => ({
    ...state,
    isChangingPaymentMethod: true,
  })),
  on(SubscriberActions.changePaymentMethodInitFinish, state => ({
    ...state,
    isChangingPaymentMethod: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return subscribersReducers(state, action);
}

export const {selectAll, selectIds, selectEntities, selectTotal} =
  adapter.getSelectors();
