import {CommonModule} from '@angular/common';
import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {BKN_3DS_STATUS} from '@constant/bkn-status';
import {ROUTES_KEYS} from '@constant/routes-keys';
import {URL_PARAMS} from '@constant/urlparams-keys';
import {environment} from '@env/environment';
import {
  AuthorizeBknRequestDto,
  AuthorizeBknResponseDto,
  ChargesRequestDto,
  CreditCardDto,
  PaymentDto,
  SubmerchantDto,
} from '@models/data-contracts';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store, select} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {ChargesService} from '@services/charges.service';
import {ErrorService} from '@services/error.service';
import {Frame3dsService} from '@services/frame3ds.service';
import {PaymentService} from '@services/payment.service';
import {ScriptService} from '@services/script-service.service';
import {ChargesActions, PaymentActions} from '@store/actions';
import * as frompayments from '@store/reducers/payment.reducer';
import * as fromSubscription from '@store/reducers/subscribers.reducers';
import {
  chargesSelectors,
  paymentSelectors,
  subscriberSelectors,
} from '@store/selectors';
import {paramsToLower} from '@utils/query-params-utils';
import {RSAHelper} from '@utils/rsa-helper';
import loadjs from 'loadjs';
import {Observable, filter, of} from 'rxjs';
import {BknPaymentFormComponent} from '../../components/bkn-payment-form/bkn-payment-form.component';

declare let dftp: any;

@UntilDestroy()
@Component({
  selector: 'app-bkn-payment',
  templateUrl: './bkn-payment.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    FormsModule,
    BknPaymentFormComponent,
  ],
})
export class BknPaymentComponent {
  @ViewChild('frame') iframe?: ElementRef;

  subMerchant?: Observable<SubmerchantDto | null | undefined>;
  payment$?: Observable<PaymentDto | null | undefined>;
  isLoading$: Observable<boolean>;
  isChangingPaymentMethod: boolean = false;

  iframeUrl?: SafeResourceUrl;

  private _lang?: string;
  private _livemode?: boolean;

  // private _chargeId?: string;
  private _form?: any;
  callbackfn = (event: any) => {
    if (event && this._form) {
      this.isLoading$ = of(true);
      //call authorize
      this.onSubmit(this._form);
    }
  };

  constructor(
    private readonly _store: Store<frompayments.State>,
    private readonly _storeSubscribers: Store<fromSubscription.State>,
    private readonly _router: Router,
    private readonly _paymentService: PaymentService,
    private readonly _chargesService: ChargesService,
    private readonly _errorService: ErrorService,
    private readonly _frameService: Frame3dsService,
    public sanitizer: DomSanitizer,
    private readonly _rsaHelper: RSAHelper,
    private readonly translate: TranslateService,
    private script: ScriptService,
    private readonly _activatedRouter: ActivatedRoute
  ) {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

    this._store.dispatch(ChargesActions.authorizeBknReset());

    this.isLoading$ = this._store.pipe(
      untilDestroyed(this),
      select(chargesSelectors.selectIsLoadingAuthorizeBkn),
      filter(item => item != undefined && item != null)
    );

    // PAYMENT INFO
    this.payment$ = this._store.pipe(
      untilDestroyed(this),
      select(paymentSelectors.selectFirstOrDefaulPayment),
      filter(item => !!item)
    );

    this._store
      .pipe(
        untilDestroyed(this),
        select(chargesSelectors.selectChargeCreateSuccess),
        filter(item => !!item)
      )
      .subscribe(charge => {
        if (charge?.id) {
          localStorage.setItem('chargeId', charge.id);

          const options = {
            attemptReference: charge?.id,
            sensitiveFields: ['securityCode'],
          };

          const dftpPromise = loadjs(environment.NETHONJS_URL, {
            returnPromise: true,
            before: (path, scriptEl: any) =>
              (scriptEl.crossOrigin = 'use-credentials'),
          })
            .then(() => dftp.init(options))
            .catch(err => console.error(err));

          dftpPromise
            .then(() => dftp.profileCompleted())
            .catch(err => console.error('Profiling failed with err: ' + err));
          // .finally(validateFormAndSend);

          // OTHER WAY TO IMPLEMENT NTHONJS
          // this.script
          //   .load([
          //     {
          //       name: 'nethonejs',
          //       marchantId: 'nethonejs',
          //       crossOriginCredentials: true,
          //     } as ScriptLoader,
          //   ])
          //   .then(data => {
          //     console.log('window.dftp', window.dftp);

          //     if (window.dftp) {
          //       window.dftp.init({
          //         attemptReference: charge.id,
          //         sensitiveFields: ['securityCode'],
          //       });
          //     }
          //   })
          //   .catch((error2: any) => {
          //     console.log('error2', error2);
          //   })
          //   .finally(() => {});
          // }
          // this._chargeId = charge?.id;
        }
      });

    this._store
      .pipe(
        untilDestroyed(this),
        select(chargesSelectors.selectAuthorizeBknSuccess),
        filter(item => !!item)
      )
      .subscribe(authorize => {
        this._store.dispatch(ChargesActions.authorizeReset());

        if (!authorize) {
          //loading stop
          this._store.dispatch(
            ChargesActions.loadingAuthorizeBkn({
              isLoading: false,
            })
          );

          this._router.navigate([ROUTES_KEYS.ERROR]);
          return;
        }

        // NOT IN 3DS MODE .. so if is success .. go to thankyou
        this.checkIframeOrRedirect(authorize);
      });

    //ERROR ON BKN AUTHORIZATION
    this._store
      .pipe(
        untilDestroyed(this),
        select(chargesSelectors.selectErrorAuthorizeBkn),
        filter(item => !!item)
      )
      .subscribe(error => {
        this._store.dispatch(ChargesActions.authorizeReset());

        if (error) {
          this._errorService.Set(error);

          this._router.navigate([ROUTES_KEYS.ERROR]);
        }
      });

    this._store
      .pipe(
        untilDestroyed(this),
        select(subscriberSelectors.selectIsChangePaymentMethod),
        filter(item => !!item)
      )
      .subscribe(isChangingPaymentMethod => {
        this.isChangingPaymentMethod = isChangingPaymentMethod;
      });
  }

  ngOnInit(): void {
    this.reset();
    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      // .pipe(untilDestroyed(this), distinctUntilChanged(), skip(1))
      .subscribe(item => {
        const res = paramsToLower(item);

        this._lang = res[URL_PARAMS.LANG];
        this._livemode = res[URL_PARAMS.LIVE_MODE];
      });

    const paymentId = this._paymentService.GetPaymentId();
    let paymentGatewayId = this._paymentService.GetGatewayPaymentId();

    //in case of resfresh on payment form
    if (!paymentGatewayId) {
      this._paymentService.SetGatewayPaymentId('nets');
      paymentGatewayId = 'nets';
    }

    this.subMerchant = this._paymentService
      .GetSubMerchantFromSession(paymentId)
      .pipe(filter(item => !!item));

    if (paymentGatewayId && paymentId) {
      //loading start

      //Get payment by id
      this._store.dispatch(PaymentActions.payment({paymentId}));

      // get payment methods availables
      this._store.dispatch(
        ChargesActions.chargesCreate({
          charges: {
            paymentGatewayId,
            paymentId,
          } as ChargesRequestDto,
        })
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.iframe) {
      window.addEventListener(
        'message',
        (event: any) => {
          if (event?.data == 'loaded' && this._form) {
            this.isLoading$ = of(true);
            //call authorize
            this.onSubmit(this._form);
          }
        },
        false
      );
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('loaded', (event: any) => {
      // console.log('removed');
    });
  }

  onSubmit(ev: any) {
    this.reset();
    this._form = ev;
    const chargeId = localStorage.getItem('chargeId');

    this._store.dispatch(
      ChargesActions.loadingAuthorizeBkn({
        isLoading: true,
      })
    );

    const {
      cardHolderName,
      creditCardNumber,
      expiredMonth,
      expiredYear,
      securityCode,
      subscriber,
    } = ev.value as CreditCardDto;

    const request = {
      chargesId: chargeId,
      body: {
        cardHolderName,
        creditCardNumber:
          this._rsaHelper.encryptWithPublicKey(creditCardNumber),
        expiredMonth,
        expiredYear: `${20}${expiredYear}`,
        securityCode: this._rsaHelper.encryptWithPublicKey(securityCode),
        authNotifyURL: window.origin + '/iframeclose.html',
        gdiNotifyURL: window.origin + '/gdiiframe.html',
        subscriber,
      } as CreditCardDto,
    } as AuthorizeBknRequestDto;

    if (chargeId) {
      this._chargesService.SaveAuthorizeBkn(request);
      //get payment methods availables
      this._store.dispatch(
        ChargesActions.authorizeBkn({
          request: request,
        })
      );
    } else {
      this._errorService.SetMessage(
        this.translate.instant('ERRORS.payment-id-missing')
      );

      this._router.navigate([ROUTES_KEYS.ERROR]);
    }
  }

  private checkIframeOrRedirect(authorize: AuthorizeBknResponseDto) {
    if (!authorize?.success) {
      this.goToerror(authorize);
      return;
    } else {
      //SUCCESS
      if (authorize.gatewayCode == BKN_3DS_STATUS.DS2.CHALLENGE) {
        return;
      }
      if (authorize.returnUrl) {
        if (authorize.tranStatus == BKN_3DS_STATUS.DS2.CHALLENGE) {
          //loading stop
          this.goToOtp(authorize);
          return;
        }

        //check if has NOt the transStatus == "CHALLENGE" .. so set returnUrl in iframe hidden
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          authorize.returnUrl
        );

        //check if HAS the transStatus == "CHALLENGE" .. so sredirect page on returnURl
      } else if (!authorize.returnUrl) {
        this._router.navigate([ROUTES_KEYS.NETS.thankyou]);
      }
    }
  }

  private goToOtp(authorize: AuthorizeBknResponseDto) {
    this._store.dispatch(
      ChargesActions.loadingAuthorizeBkn({
        isLoading: false,
      })
    );

    this._frameService.setOtpUri(authorize.returnUrl!);
    this._router.navigate([ROUTES_KEYS.NETS.otp], {
      queryParams: {
        lang: this._lang,
        livemode: this._livemode,
      },
    });
  }

  private goToerror(authorize: AuthorizeBknResponseDto) {
    const error = authorize.gatewayCode
      ? this.translateError(authorize.gatewayCode)
      : 'ERRORS.INTERNAL_SERVER_ERROR.SomethingWentWrong';

    this._errorService.SetMessage(error);

    //loading stop
    this._store.dispatch(
      ChargesActions.loadingAuthorizeBkn({
        isLoading: false,
      })
    );

    this._router.navigate([ROUTES_KEYS.ERROR]);
  }

  private reset() {
    this._store.dispatch(PaymentActions.paymentReset());
    this._store.dispatch(PaymentActions.submerchantReset());

    this._store.dispatch(ChargesActions.chargesCreateReset());
    this._store.dispatch(PaymentActions.createPaymentLinkReset());
  }

  private translateError(error: string) {
    let traslated = this.translate.instant(error);
    traslated = traslated ? traslated : error.replace('_', ' ');

    return traslated;
  }
}
