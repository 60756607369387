import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromSubscribers from '../reducers/subscribers.reducers';

export const SubscribersFeatureSelector =
  createFeatureSelector<fromSubscribers.State>(
    fromSubscribers.subscribersFeatureKey
  );

export const selectPaymentId = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.paymentId;
  }
);

export const selectErrorInitialize = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.error;
  }
);

export const selectIsLoadingInitialize = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.isLoadingInitialize;
  }
);

export const selectSubscriberErrorInitialize = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.error;
  }
);

export const selectSubscriberIsLoadingInitialize = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.isLoadingInitialize;
  }
);

export const selectSubscrition = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.subscription;
  }
);

export const selectSubscritionError = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.errorSubscription;
  }
);

export const selectIsLoadingSubscrition = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.isLoadingSubscription;
  }
);

export const selectAddSubscriber = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.added;
  }
);

export const selectAddSubscriberError = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.errorAddsubscriber;
  }
);

export const selectIsLoadinAddSubscriber = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.isLoadingAddsubscriber;
  }
);

export const selectSubscriberId = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.subscriberId;
  }
);

export const selectIsChangePaymentMethod = createSelector(
  SubscribersFeatureSelector,
  state => {
    return state.isChangingPaymentMethod;
  }
);
