import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromPayment from '../reducers/payment.reducer';

export const PaymentFeatureSelector = createFeatureSelector<fromPayment.State>(
  fromPayment.paymentFeatureKey
);

export const selectAllPaymentGateways = createSelector(
  PaymentFeatureSelector,
  state => {
    return state.submerchant?.paymentGateways;
  }
);

export const selectFirstOrDefaulMerchant = createSelector(
  PaymentFeatureSelector,
  state => {
    return state.submerchant;
  }
);

export const selectMerchantError = createSelector(
  PaymentFeatureSelector,
  state => {
    return state.errorSubmerchant;
  }
);

export const selectFirstOrDefaulPayment = createSelector(
  PaymentFeatureSelector,
  state => {
    return state.payment;
  }
);

export const selectPaymentError = createSelector(
  PaymentFeatureSelector,
  state => {
    return state.error;
  }
);

export const selectFirstOrDefaulPaymentCreated = createSelector(
  PaymentFeatureSelector,
  state => {
    return state.paymentCreate;
  }
);

export const selectPaymentCreatedError = createSelector(
  PaymentFeatureSelector,
  state => {
    return state.errorCreate;
  }
);

// export const selectValidate = createSelector(PaymentFeatureSelector, state => {
//   return state.validate;
// });

// export const selectValidateError = createSelector(
//   PaymentFeatureSelector,
//   state => {
//     return state.errorValidate;
//   }
// );
