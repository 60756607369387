import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {SubscriptionService} from '../../services/subscriptions/subscription.service';
import * as SubscriberActions from '../actions/subscribers.action';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionEffects {
  constructor(
    private _actions$: Actions,
    private _subscribersService: SubscriptionService
  ) {}

  Initialize$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SubscriberActions.initialization),
      concatMap(xx =>
        this._subscribersService
          .Initialize(xx.id, xx.externalId, xx.cancelUrl, xx.returnUrl)
          .pipe(
            map(resp => SubscriberActions.initializationSuccess({id: resp.id})),
            catchError(errorMessage => {
              return of(SubscriberActions.initializationError({errorMessage}));
            })
          )
      )
    )
  );

  InitializeSubscriber$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SubscriberActions.Subcriberinitialization),
      concatMap(xx =>
        this._subscribersService.InitializeSubscriber(xx.id).pipe(
          map(resp =>
            SubscriberActions.SubcriberinitializationSuccess({id: resp.id})
          ),
          catchError(errorMessage => {
            return of(
              SubscriberActions.SubcriberinitializationError({errorMessage})
            );
          })
        )
      )
    )
  );

  subscription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SubscriberActions.subscription),
      concatMap(xx =>
        this._subscribersService.Find(xx.id).pipe(
          map(subscription => {
            localStorage.setItem(
              `submerchant_sub_${subscription.id}`,
              JSON.stringify(subscription)
            );
            return SubscriberActions.subscriptionSuccess({subscription});
          }),
          catchError(errorMessage => {
            return of(SubscriberActions.subscriptionError({errorMessage}));
          })
        )
      )
    )
  );

  AddSubscriber$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SubscriberActions.addSubscriber),
      concatMap(xx =>
        this._subscribersService.AddSubscriber(xx.subscriber).pipe(
          map(resp => SubscriberActions.addSubscriberSuccess(resp)),
          catchError(errorMessage => {
            return of(SubscriberActions.addSubscriberError({errorMessage}));
          })
        )
      )
    )
  );
}
