import {LOCALSTORAGE_KEYS} from '@constant/index';
import {Settings} from '@models/index';
import {TranslateService} from '@ngx-translate/core';
import {LocateService} from '@services/locate.service';
import {MultilinguaService} from '@services/multilingua.service';
import {SettingsService} from '@services/settings.service';

//APP_INITIALIZER FOR SET LANGIAGES
export function startupServiceLoad(
  _settingsService: SettingsService,
  transalte: TranslateService,
  locateService: LocateService
) {
  return () =>
    new Promise((resolve, reject) => {
      _settingsService.Get().subscribe({
        next: (conf: Settings) => {
          _settingsService.SetColors(conf);
          _settingsService.SetFavicon(conf);

          const lang_from_url = new URL(location.href).searchParams.get(
            LOCALSTORAGE_KEYS.LANG
          );

          //ADD ALL LANGUAGES IN CONFIG
          transalte.addLangs(conf.languages.map(xx => xx.prefix));

          const language_from_storage = localStorage.getItem(
            LOCALSTORAGE_KEYS.LANG
          );

          let default_language = lang_from_url
            ? lang_from_url
            : language_from_storage
            ? language_from_storage
            : conf.languages.length > 0
            ? conf.languages.find(xx => xx.isDefault)?.prefix
            : conf.languages[0]?.prefix || MultilinguaService.getDefaultLang();

          default_language =
            default_language || MultilinguaService.getDefaultLang();

          //set in localstorage if not presente
          localStorage.setItem(LOCALSTORAGE_KEYS.LANG, default_language);

          //SET DEFAULT AND FALLBACK
          transalte.setDefaultLang(default_language);

          //GET SELECTED LANG OR BROWSER SET LANG
          let browserLang = localStorage.getItem(LOCALSTORAGE_KEYS.LANG)
            ? localStorage.getItem(LOCALSTORAGE_KEYS.LANG)
            : MultilinguaService.getDefaultLang();
          // : navigator.language;

          //LOAD DYNAMICALLY registerLocaldata for given lang in settings
          locateService.localeInitializer(conf.languages).finally(() => {
            transalte.use(browserLang!);

            //multiLingua.changeDir(browserLang);

            resolve(true);
          });
        },
        error(err) {
          reject(false);
        },
      });
    });
}
