import {Routes} from '@angular/router';
import {FulllayoutComponent} from './layouts/fulllayout/fulllayout.component';
import {BknCloseTransactionComponent} from './pages/bkn-close-transaction/bkn-close-transaction.component';
import {BknPaymentComponent} from './pages/bkn-payment/bkn-payment.component';
import {BknSecureComponent} from './pages/bkn-secure/bkn-secure.component';
import {ErrorInternalComponent} from './pages/error-internal/error-internal.component';
import {GeorgianPaymentComponent} from './pages/external-payment/georgian-payment/georgian-payment.component';
import {HomeComponent} from './pages/home/home.component';
import {SubscriberInitializationComponent} from './pages/subscriptions/subscriber-initialization/subscriber-initialization.component';
import {SubscriptionInitializationComponent} from './pages/subscriptions/subscription-initialization/subscription-initialization.component';
import {SubscriptionsComponent} from './pages/subscriptions/subscriptions.component';
import {ThankYouComponent} from './pages/thank-you/thank-you.component';

export const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/full/home'},
  {
    path: 'full',
    component: FulllayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: '3ds2',
        component: BknSecureComponent,
      },
      {
        path: 'thankyou',
        component: ThankYouComponent,
      },
      {
        path: 'finish',
        component: BknCloseTransactionComponent,
      },
      {
        path: 'error-internal',
        component: ErrorInternalComponent,
      },
      {
        path: 'payments',
        component: BknPaymentComponent,
      },

      //* for External payment Georgia

      {
        path: 'gcge',
        component: GeorgianPaymentComponent,
      },
    ],
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: SubscriptionsComponent,
        children: [
          {
            path: 'subscription',
            component: SubscriptionInitializationComponent,
          },
        ],
      },
      {
        path: 'subscribers',
        component: SubscriberInitializationComponent,
      },
    ],
  },
];
