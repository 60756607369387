import {AsyncPipe, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {Personalization} from '@models/theme.interface';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateModule} from '@ngx-translate/core';
import {ThemeFacade} from '@store/index';
import {BehaviorSubject} from 'rxjs';
import {HeaderLanguageComponent} from '../header-language/header-language.component';
@UntilDestroy()
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgIf, HeaderLanguageComponent, TranslateModule, AsyncPipe],
  template: `
    <div class="row" *ngIf="!liveMode">
      <div class="hint d-flex justify-content-center align-items-center">
        <span nz-icon nzType="warning" nzTheme="outline"></span>
        <p class="m-0">
          {{ 'HEADER_PAYMENT.testHint' | translate }}
        </p>
      </div>
    </div>
    <div>
      <div class="container-fluid header">
        <div class="header-content ">
          <div class="header__logo">
            <img class="" [src]="headerLogo | async" alt="logo" />
          </div>
        </div>
        <div
          class="header__translation d-flex justify-content-end align-items-center h-100"
        >
          <app-header-language></app-header-language>
        </div>
      </div>
    </div>
  `,
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  headerLogo = new BehaviorSubject<string>('');
  @Input() liveMode: boolean | null = false;
  _themeFacade = inject(ThemeFacade);
  constructor() {
    this._themeFacade.theme$
      .pipe(untilDestroyed(this))
      .subscribe((theme: Personalization | undefined) => {
        if (theme) {
          this.headerLogo.next(theme.logo);
        }
      });
  }
}
