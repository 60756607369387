import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cardNumberStyle',
  standalone: true,
})
export class CardNumberStylePipe implements PipeTransform {
  transform(value?: string | null | undefined, args?: any): any {
    value =
      value && value.length <= 19
        ? value
            .replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim()
        : value;

    return value;
  }
}
