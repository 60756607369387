import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCharges from '../reducers/charges.reducer';

export const ChargesFeatureSelector = createFeatureSelector<fromCharges.State>(
  fromCharges.chargesFeatureKey
);

export const selectFirstOrDefaulCharges = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.charges;
  }
);

export const selectCharge = createSelector(ChargesFeatureSelector, state => {
  return state.charge;
});

export const selectChargeCreateSuccess = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.charges;
  }
);

export const selectAuthorizeBknSuccess = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.authorizeBkn;
  }
);

export const selectErrorAuthorizeBkn = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.errorBknAuthorize;
  }
);

export const selectIsLoadingAuthorizeBkn = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.isLoadingAuthorizeBkn;
  }
);

export const selectErrorCharge = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.errorCharge;
  }
);

export const selectIsLoadingCharge = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.isLoadingCharge;
  }
);

export const selectChargesError = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.error;
  }
);

export const selectFirstOrDefaulAuthorize = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.authorize;
  }
);

export const selectAuthorizeError = createSelector(
  ChargesFeatureSelector,
  state => {
    return state.error;
  }
);
