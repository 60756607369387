import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { URL_PARAMS } from '@constant/index';
import { PaymentDto } from '@models/data-contracts';
import { Personalization } from '@models/theme.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { SubmerchantFacade } from '@store/facade/submerchant-facade.service';
import {
  PaymentReducers,
  paymentSelectors,
  SubscriptionFacade,
  ThemeFacade,
} from '@store/index';
import { paramsToLower } from '@utils/query-params-utils';
import { filter, Observable } from 'rxjs';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';

@UntilDestroy()
@Component({
  selector: 'app-fulllayout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
  template: ` <app-header [liveMode]="liveMode"></app-header>

    <router-outlet></router-outlet>

    <app-footer></app-footer>`,
  styleUrl: './fulllayout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FulllayoutComponent implements OnInit {
  liveMode: boolean = true;
  _submerchantFacade = inject(SubmerchantFacade);
  _themeFacade = inject(ThemeFacade);
  _subsctiptionFacade = inject(SubscriptionFacade);
  _store = inject(Store<PaymentReducers.State>);
  payment$?: Observable<PaymentDto | null | undefined>;
  constructor(private _activatedRouter: ActivatedRoute) {}

  ngOnInit(): void {
    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(item => {
        const res = paramsToLower(item);

        const id = res[URL_PARAMS.PAYMENT_ID];
        const paymentId = res[URL_PARAMS.PAYMENT_ID];
        const subscriptionId = res[URL_PARAMS.SUBSCRIPTION_ID];
        if (id) {
          this._submerchantFacade.GetSubmerchant(id);
          this._themeFacade.getTheme(id);
        }
        if (paymentId) {
          this._themeFacade.getTheme(paymentId);
        }
        if (subscriptionId) {
          this._subsctiptionFacade.getSubscription(subscriptionId);
        }
        this._themeFacade.theme$
          .pipe(untilDestroyed(this))
          .subscribe((theme: Personalization | undefined) => {
            if (!theme) return;
            document.documentElement.style.setProperty(
              '--theme-pc',
              theme.payButtonColor
            );
            document.documentElement.style.setProperty(
              '--theme-tc',
              theme.payButtonTextColor
            );
            document.documentElement.style.setProperty(
              '--theme-bgc',
              theme.payBackgroundColor
            );

            document.documentElement.style.setProperty(
              '--theme-pca',
              this.convertHexaToRGBA(theme.payButtonColor, 0.1)
            );
          });

        this.payment$ = this._store.pipe(
          untilDestroyed(this),
          select(paymentSelectors.selectFirstOrDefaulPayment),
          filter(item => !!item)
        );

        this.payment$.pipe(untilDestroyed(this)).subscribe(payment => {
          if (payment) {
            this.liveMode = payment?.liveMode;
          }
        });
      });
  }

  private convertHexaToRGBA(hexa: string, opacity: number = 1) {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexa)) {
      c = hexa.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        `,${opacity})`
      );
    }
    return null;
  }
}
