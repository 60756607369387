import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ThemeService} from '@services/theme.service';
import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import * as ThemeActions from '../actions/theme.action';
@Injectable({
  providedIn: 'root',
})
export class ThemeEffects {
  constructor(
    private _actions$: Actions,
    private _themeService: ThemeService
  ) {}

  submerchant$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ThemeActions.getMerchantTheme),
      concatMap(xx =>
        this._themeService.getTheme(xx.id).pipe(
          map(theme => ThemeActions.getMerchantThemeSuccess({theme})),
          catchError(errorMessage => {
            return of(ThemeActions.getMerchantThemeError(errorMessage));
          })
        )
      )
    )
  );
}
