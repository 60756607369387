import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'customCurrency',
  standalone: true,
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): any {
    if (value === null || value === undefined) return;

    if (
      localStorage.getItem('lang') === 'it' ||
      localStorage.getItem('lang') === 'en'
    ) {
      return value.toLocaleString('us-US', {minimumFractionDigits: 2});
    } else if (localStorage.getItem('lang') === 'ar') {
      return value.toLocaleString('ar-AE', {minimumFractionDigits: 2});
    }
  }
}
