import {createFeatureSelector, createSelector} from '@ngrx/store';
import {LoadingReducers} from '../reducers';

export const LoadingFeatureSelector =
  createFeatureSelector<LoadingReducers.State>(
    LoadingReducers.loaderFeatureKey
  );

export const selectLoading = createSelector(LoadingFeatureSelector, state => {
  return state?.isLoading || false; //true ;
});
