import {AsyncPipe, NgFor, NgIf, UpperCasePipe} from '@angular/common';
import {Component, inject} from '@angular/core';
import {Language} from '@models/index';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {MultilinguaService} from '@services/multilingua.service';
import {SettingsService} from '@services/settings.service';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {Observable, map} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-header-language',
  standalone: true,
  imports: [
    NgFor,
    NzDropDownModule,
    AsyncPipe,
    UpperCasePipe,
    NgIf,
    NzIconModule,
  ],
  template: `<a
      class="lang-menu"
      nz-dropdown
      [nzDropdownMenu]="menu"
      nzTrigger="click"
      nzPlacement="bottomRight"
      nzOverlayClassName="lang-root-menu"
    >
      <span nz-icon nzType="global" nzTheme="outline"></span>
      <span class="text-white ">
        {{ selectedLanguage | uppercase }}
      </span>
    </a>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <ng-container *ngFor="let lang of languages$ | async; let i = index">
          <li nz-menu-item (click)="select(lang)">
            <div class="row">
              <div class="col-4">
                <span
                  *ngIf="lang.selected"
                  nz-icon
                  nzTheme="outline"
                  class="align-text-top"
                  nzType="check"
                  style="color: #ffa06a"
                ></span>
              </div>
              <div class="col-4 text-uppercase text-center">
                {{ lang.prefix === 'ka' ? 'GE' : lang.prefix }}
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </nz-dropdown-menu> `,
  styleUrl: './header-language.component.scss',
})
export class HeaderLanguageComponent {
  languages$?: Observable<Language[]>;
  isOpenMenu: boolean = false;
  selectedLanguage: string = '';

  translateService = inject(TranslateService);
  _settingService = inject(SettingsService);
  _multiLinguaService = inject(MultilinguaService);

  constructor() {
    this.selectedLanguage = this.translateService.currentLang;
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');

    this.languages$ = this._settingService.Get().pipe(
      map(xx =>
        xx.languages.map(lg => {
          return {
            full: lg.full,
            isDefault: lg.isDefault,
            prefix: lg.prefix,
            selected: lg.prefix == lang,
          } as Language;
        })
      )
    );
  }

  select(ev: Language) {
    this._multiLinguaService.switchLanguage(ev.prefix);
  }
}
