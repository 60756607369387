import {Injectable} from '@angular/core';
import {LOCALSTORAGE_KEYS} from '../constant';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  clearStorage() {
    const language_from_storage = localStorage.getItem(LOCALSTORAGE_KEYS.LANG);
    localStorage.clear();
    if (language_from_storage)
      localStorage.setItem(LOCALSTORAGE_KEYS.LANG, language_from_storage);
  }
}
