import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ChargesService} from '../../services/charges.service';

import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import * as ChargesActions from '../actions/charges.action';

@Injectable({
  providedIn: 'root',
})
export class ChargesEffects {
  constructor(
    private _actions$: Actions,

    private _chargesService: ChargesService
  ) {}

  Charge$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChargesActions.charge),

      concatMap(input =>
        this._chargesService.Get(input.id).pipe(
          // tap((xx) => this._spinner.show()),
          map(response => ChargesActions.chargeSuccess({response})),
          // tap((xx) => this._spinner.hide()),
          catchError(errorMessage =>
            of(ChargesActions.chargeError(errorMessage))
          )
        )
      )
    )
  );

  createCharges$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChargesActions.chargesCreate),

      concatMap(input =>
        this._chargesService.Create(input.charges).pipe(
          // tap((xx) => this._spinner.show()),
          map(charges => ChargesActions.chargesCreateSuccess({charges})),
          // tap((xx) => this._spinner.hide()),
          catchError(errorMessage =>
            of(ChargesActions.chargesCreateError({error: errorMessage}))
          )
        )
      )
    )
  );

  authorize$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChargesActions.authorize),

      concatMap(input =>
        this._chargesService.Authorize(input.request).pipe(
          map(authorize => ChargesActions.authorizeSuccess({authorize})),
          catchError(errorMessage =>
            of(ChargesActions.authorizeError(errorMessage))
          )
        )
      )
    )
  );

  authorizeBkn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ChargesActions.authorizeBkn),

      concatMap(input =>
        this._chargesService.AuthorizeBkn(input.request).pipe(
          map(authorize => ChargesActions.authorizeBknSuccess({authorize})),
          catchError(error => of(ChargesActions.authorizeBknError({error})))
        )
      )
    )
  );
}
