import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {SubmerchantDto} from '@models/index';
import {TranslateModule} from '@ngx-translate/core';
import {CustomCurrencyPipe} from '@pipes/custom-currency.pipe';

@Component({
  selector: 'app-btn-submerchant-pay',
  standalone: true,
  imports: [CommonModule, TranslateModule, CustomCurrencyPipe],
  template: `
    <ng-container *ngIf="subMerchant as sub">
      <!-- BUTTON TYPE SUBMIT -->
      <ng-container *ngIf="btnType == 'submit'">
        <button
          class="btn btn-primary w-100 py-3 mb-3"
          type="submit"
          [tabindex]="tabindex"
          [disabled]="isPaying || disable"
        >
          <ng-container [ngSwitch]="sub.channel">
            <ng-container *ngSwitchCase="'InitializeRecurring'">
              <div *ngIf="!isChangingPaymentMethod; else updatePaymentMethod">
                <span
                  class="fs-14"
                  *ngIf="!sub.recurring?.trialPeriod && sub.recurring as rec"
                >
                  {{ 'BKN.SUBSCRIPTION.SubscribeNow' | translate }}
                  {{
                    rec.amount
                      | currency : rec.currency : 'symbol' : '1.2-2' : 'it'
                  }}
                  /
                  <!--  -->
                  @switch ( rec.frequency) {
                  <!--  -->
                  @case('daily') {
                  {{ 'BKN.SUBSCRIPTION.daily' | translate }}
                  } @case('weekly') {
                  {{ 'BKN.SUBSCRIPTION.weekly' | translate }}
                  } @case('monthly') {
                  {{ 'BKN.SUBSCRIPTION.monthly' | translate }}
                  } @case('annual') {
                  {{ 'BKN.SUBSCRIPTION.annual' | translate }}
                  } @default {
                  {{ rec.frequency }}
                  } }
                </span>
                <span
                  class="fs-14"
                  *ngIf="sub.recurring && sub.recurring.trialPeriod > 0"
                >
                  {{
                    'BKN.SUBSCRIPTION.StartTrial'
                      | translate : {days: sub.recurring.trialPeriod}
                  }}
                  <!-- Start your {{ sub.recurring.trialPeriod }}-days trial -->
                </span>
              </div>
              <ng-template #updatePaymentMethod>
                <span class="fs-14">
                  {{ 'BKN.SUBSCRIPTION.UpdatePayment' | translate }}
                </span>
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="fs-14">
                {{ 'BKN.PAYMENT_FORM.PaySafely' | translate }}

                <span>
                  {{
                    sub.amount
                      | currency : sub.currency : 'symbol' : '1.2-2' : 'it'
                  }}
                </span>
              </span>
            </ng-container>
          </ng-container>
        </button>
        <div
          *ngIf="
            isChangingPaymentMethod != true &&
              sub.channel === 'InitializeRecurring' &&
              sub.recurring as rec;
            else payment
          "
          class="col-12 fs-14 text-center"
        >
          <span *ngIf="rec.trialPeriod === 0">
            {{ 'BKN.SUBSCRIPTION.SubscribeFor' | translate }}
            {{ rec.amount | customCurrency }} {{ sub.currency }} /
            {{ 'BKN.SUBSCRIPTION.' + rec.frequency | translate }}
          </span>
          <span *ngIf="rec.trialPeriod > 0">
            {{ 'BKN.SUBSCRIPTION.After' | translate }} {{ rec.trialPeriod }}
            {{
              rec.trialPeriod === 1
                ? ('BKN.SUBSCRIPTION.day' | translate)
                : ('BKN.SUBSCRIPTION.days' | translate)
            }}
            <span class="text-lowercase">{{
              'BKN.SUBSCRIPTION.SubscribeFor' | translate
            }}</span>
            {{ rec.amount | customCurrency }} {{ sub.currency }} /
            {{ 'BKN.SUBSCRIPTION.' + rec.frequency | translate }}
          </span>
        </div>
        <ng-template #payment>
          <div class="col-12 fs-14 text-center">
            <!-- {{ 'BKN.PAYMENT_FORM.AmountToPay' | translate }}
        {{ subMerchant.amount | customCurrency }}
        {{ subMerchant.currency }} -->
          </div>
        </ng-template>
      </ng-container>

      <!-- BUTTON TYPE BUTTON -->
      <ng-container *ngIf="btnType == 'button'">
        <button
          class="btn btn-primary w-100 py-3 mb-3"
          type="button"
          [tabindex]="tabindex"
          [disabled]="isPaying"
          (click)="clickEvent.emit($event)"
        >
          <ng-container [ngSwitch]="sub.channel">
            <ng-container *ngSwitchCase="'InitializeRecurring'">
              <span class="fs-14">
                {{ 'HOME.SUBSCRIPTION.PaymentFor' | translate }}
                <span *ngIf="sub.recurring as rec">
                  {{ rec.amount | customCurrency }}
                  {{ sub.currency }} /
                  {{ 'BKN.SUBSCRIPTION.' + rec.frequency | translate }}
                </span>
              </span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="fs-14">
                {{ (label ? label : 'HOME.Pay') | translate }}
                <span>
                  {{ sub.amount | customCurrency }} {{ sub.currency }}
                </span>
              </span>
            </ng-container>
          </ng-container>
        </button>
      </ng-container>
    </ng-container>
  `,
  styleUrl: './btn-submerchant-pay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnSubmerchantPayComponent {
  @Input() disable: boolean = true;
  @Input() subMerchant?: SubmerchantDto | null | undefined;
  @Input() isPaying: boolean = false;
  @Input() label?: string;
  @Input() tabindex?: number;
  @Input() btnType: 'submit' | 'button' = 'submit';
  @Input() isChangingPaymentMethod: boolean | null | undefined = false;

  @Output() clickEvent: EventEmitter<any> = new EventEmitter();
}
