import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {ChargesDto} from '../../models/data-contracts';
import {LoadingActions} from '../actions';

export const loaderFeatureKey = 'loader';

export interface State extends EntityState<any> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<ChargesDto> =
  createEntityAdapter<ChargesDto>({});

export const initialState: State = adapter.getInitialState({
  entities: [],

  isLoading: false, //true
});

export const loadingReducers = createReducer(
  initialState,

  on(LoadingActions.loading, (state, {}) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(LoadingActions.hide, (state, {}) => ({
    ...state,
    isLoading: false, //true
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return loadingReducers(state, action);
}

export const {selectAll, selectIds, selectEntities, selectTotal} =
  adapter.getSelectors();
