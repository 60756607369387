import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as ROUTES from '@constant/routes-keys';
import {ROUTES_KEYS} from '@constant/routes-keys';
import {URL_PARAMS} from '@constant/urlparams-keys';
import {untilDestroyed} from '@ngneat/until-destroy';
import {ErrorService} from '@services/error.service';
import {SubscriptionFacade} from '@store/facade/subscription-facade.service';
import {NzSpinComponent} from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-subscriber-initialization',
  templateUrl: './subscriber-initialization.component.html',
  standalone: true,
  imports: [CommonModule, NzSpinComponent],
})
export class SubscriberInitializationComponent implements OnInit {
  isLoading: boolean = true;
  _lang: string = 'en';
  _livemode: boolean = false;

  constructor(
    private _activatedRouter: ActivatedRoute,
    private _router: Router,
    private readonly _errorService: ErrorService,
    private readonly SubscriptionFacade: SubscriptionFacade
  ) {
    // on Initialize success .. get back the paymentid .. and pass to mormal flow

    this.SubscriptionFacade.selectPaymentId$
      .pipe(untilDestroyed(this))
      .subscribe(paymentId => {
        if (paymentId) {
          this._router.navigate([ROUTES_KEYS.HOME], {
            queryParams: {
              paymentId,
              lang: this._lang,
              livemode: this._livemode,
            },
          });
        }
      });

    //on Initialize error
    this.SubscriptionFacade.selectErrorInitialize$
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        this._errorService.Clear();
        this._errorService.Set(error);

        this._router.navigate([ROUTES.ROUTES_KEYS.ERROR]);
      });
  }

  ngOnInit(): void {
    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      // .pipe(untilDestroyed(this), distinctUntilChanged(), skip(1))
      .subscribe(res => {
        const subscriberId = res[URL_PARAMS.SUBSCRIBER_ID];

        this._livemode = res[URL_PARAMS.LIVE_MODE];
        this._lang = res[URL_PARAMS.LANG];

        if (!subscriberId) {
          this._router.navigate([ROUTES.ROUTES_KEYS.ERROR]);
        }

        if (subscriberId) {
          this.SubscriptionFacade.initChangePaymentMethod();
          this.SubscriptionFacade.InitializeSubscription(subscriberId);
        }
      });
  }
}
