import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MultilinguaService} from '../services/multilingua.service';
import {ValidationError} from './validation-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandling {
  private readonly _defaultKey: string =
    'ERRORS.INTERNAL_SERVER_ERROR.SomethingWentWrong';

  constructor(private readonly _multiLinguaService: MultilinguaService) {}

  HandleError(err: HttpErrorResponse | any): string {
    if (!err) return this._multiLinguaService.getValueGeneric(this._defaultKey);

    if (err.status >= 500)
      return this._multiLinguaService.getValueGeneric(this._defaultKey);

    let casted_error = err as ValidationError;

    casted_error =
      casted_error && casted_error.detail
        ? casted_error
        : casted_error
        ? (err.error as ValidationError)
        : casted_error;
    if (!casted_error || (!casted_error.errorCode && !casted_error.detail))
      return this._multiLinguaService.getValueGeneric(this._defaultKey);

    const translation = casted_error.errorCode
      ? this._multiLinguaService.getValueGeneric(
          `ERRORS.${
            casted_error.errorCode || 'INTERNAL_SERVER_ERROR.SomethingWentWrong'
          }`
        )
      : casted_error.detail ||
        this._multiLinguaService.getValueGeneric(
          `ERRORS.${'INTERNAL_SERVER_ERROR.SomethingWentWrong'}`
        );

    return translation || '';
  }
}
