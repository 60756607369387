export const environment = {
  production: true,
  // API_URL: 'https://localhost:5006/api/app/',
  API_URL: 'https://staging-frontendgateway301pay.bkn301.sm/api/app/',
  API_VOUCHER_URL: 'https://staging-voucher.api.bkn301.com/api/v1/',
  // https://api.pay.bkn.outmatic.pl/api/app/paymentsLink/61e8018a500038f5de0b196d
  TIME_OUT_REDIRECT: 4000,
  PAY_PAYL_ID:
    'AQgdCQWQMMLKDh_ajBk1iNNu-ndDIMcqsCDEdxDrEOpwZRBLQyHhyYRJfJ_3W4M-WBKJyCzp6A9EfRCh',
  NETHONJS_URL: 'https://d354c9v5bptm0r.cloudfront.net/s/287436/dAtCKS.js',
  MASTERCARD_API_VERSION: 61,
};
