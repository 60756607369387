import {Params} from '@angular/router';

export const paramsToLower = (params: Params): Params => {
  const lowerParams: Params = {};
  for (const key in params) {
    lowerParams[key.toLowerCase()] = params[key];
  }

  return lowerParams;
};
