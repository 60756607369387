import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {AuthorizeResponseDto} from '../../models';
import {AuthorizeDto, ChargesDto} from '../../models/data-contracts';
import * as ChargesActions from '../actions/charges.action';
import {AuthorizeBknResponseDto} from './../../models/data-contracts';

export const chargesFeatureKey = 'charges';

export interface State extends EntityState<ChargesDto> {
  error?: any;
  errorCharge?: any;
  errorBknAuthorize?: any;
  authorizeError?: any;
  charges?: ChargesDto;
  charge?: AuthorizeResponseDto;
  authorize?: AuthorizeDto;
  authorizeBkn?: AuthorizeBknResponseDto;
  isLoadingCharge: boolean;
  isLoadingAuthorizeBkn: boolean;
}

export const adapter: EntityAdapter<ChargesDto> =
  createEntityAdapter<ChargesDto>({});

export const initialState: State = adapter.getInitialState({
  entities: [],
  error: undefined,
  errorCharge: undefined,
  errorBknAuthorize: undefined,
  authorizeError: undefined,
  charges: undefined,
  charge: undefined,
  authorize: undefined,
  authorizeBkn: undefined,
  ids: [],
  isLoadingCharge: false,
  isLoadingAuthorizeBkn: false,
});

export const chargesReducers = createReducer(
  initialState,

  on(ChargesActions.charge, state => {
    return {
      ...state,
      charge: undefined,
      errorCharge: undefined,
      isLoadingCharge: true,
    };
  }),
  on(ChargesActions.chargeSuccess, (state, {response}) => ({
    ...state,
    charge: response,
    errorCharge: undefined,
    isLoadingCharge: false,
  })),
  on(ChargesActions.chargeError, (state, {error}) => ({
    ...state,
    charge: undefined,
    errorCharge: error,
    isLoadingCharge: false,
  })),
  on(ChargesActions.chargeReset, state => ({
    ...state,
    charge: undefined,
    errorCharge: undefined,
    isLoadingCharge: false,
  })),
  on(ChargesActions.chargesCreateSuccess, (state, {charges}) => {
    return {
      ...state,
      charges,
      error: undefined,
    };
  }),
  on(ChargesActions.chargesCreateError, (state, {error}) => ({
    ...state,
    error: error,
  })),
  on(ChargesActions.chargesCreateReset, state => ({
    ...state,
    error: undefined,
    charges: undefined,
  })),
  on(ChargesActions.authorizeSuccess, (state, {authorize}) => {
    return {
      ...state,
      authorize,
      authorizeError: undefined,
    };
  }),
  on(ChargesActions.authorizeError, (state, {error}) => ({
    ...state,
    authorizeError: error,
  })),
  on(ChargesActions.authorizeReset, state => ({
    ...state,
    authorizeError: undefined,
    authorize: undefined,
  })),

  on(ChargesActions.authorizeBkn, state => {
    return {
      ...state,
      authorizeBkn: undefined,
      errorBknAuthorize: undefined,
      isLoadingAuthorizeBkn: true,
    };
  }),
  on(ChargesActions.authorizeBknSuccess, (state, {authorize}) => {
    return {
      ...state,
      authorizeBkn: authorize,
      errorBknAuthorize: undefined,
      // isLoadingAuthorizeBkn: false,
    };
  }),
  on(ChargesActions.authorizeBknError, (state, {error}) => ({
    ...state,
    authorizeBkn: undefined,
    errorBknAuthorize: error,
    // isLoadingAuthorizeBkn: false,
  })),
  on(ChargesActions.authorizeBknReset, state => ({
    ...state,
    authorizeBkn: undefined,
    errorBknAuthorize: undefined,
    isLoadingAuthorizeBkn: false,
  })),
  on(ChargesActions.loadingAuthorizeBkn, (state, {isLoading}) => ({
    ...state,
    isLoadingAuthorizeBkn: isLoading,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return chargesReducers(state, action);
}

export const {selectAll, selectIds, selectEntities, selectTotal} =
  adapter.getSelectors();
