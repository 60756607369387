import {CommonModule} from '@angular/common';
import {Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTES_KEYS, URL_PARAMS} from '@constant/index';
import {PaymentErrorControllers, Settings, SubmerchantDto} from '@models/index';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ErrorService} from '@services/error.service';
import {SettingsService} from '@services/settings.service';
import {SubmerchantFacade} from '@store/facade/submerchant-facade.service';
import {ChargesFacade} from '@store/index';
import {paramsToLower} from '@utils/query-params-utils';
import {Observable, filter} from 'rxjs';
import {StatusCardComponent} from '../../components/status-card/status-card.component';

@UntilDestroy()
@Component({
  selector: 'app-error-internal',
  standalone: true,
  template: `
    <app-status-card
      [icon]="'close'"
      [status]="'error'"
      [title]="'CARDS_STATUS.ERROR.Title' | translate"
      [description]="'CARDS_STATUS.ERROR.Description' | translate"
      [controllers]="controllers"
    >
    </app-status-card>
  `,
  styles:
    ':host { display: flex; justify-content: center; align-items: center; height: 100%; }',
  imports: [CommonModule, TranslateModule, StatusCardComponent],
})
export class ErrorInternalComponent implements OnInit {
  private _configurationService = inject(SettingsService);
  private _errorService = inject(ErrorService);
  private _activatedRouter = inject(ActivatedRoute);
  private _submerchantFacade = inject(SubmerchantFacade);
  private _router = inject(Router);

  _chargeFacade = inject(ChargesFacade);
  configuration: Observable<Settings>;
  submerchant$?: Observable<SubmerchantDto | undefined>;
  translationSerivce = inject(TranslateService);
  error: string = '';

  private _lang?: string;
  private _livemode?: boolean;
  private _paymentId?: string;

  controllers: PaymentErrorControllers[] = [];
  constructor() {
    this.configuration = this._configurationService.Get();
    this.error = this._errorService.Get();
    this.submerchant$ = this._submerchantFacade.Submerchant$.pipe(
      untilDestroyed(this),
      filter(item => !!item)
    );
  }

  ngOnInit(): void {
    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(item => {
        const res = paramsToLower(item);

        this._lang = res[URL_PARAMS.LANG];
        this._livemode = res[URL_PARAMS.LIVE_MODE];
        this._paymentId = res[URL_PARAMS.PAYMENT_ID];

        if (this._paymentId) {
          this._submerchantFacade.GetSubmerchant(this._paymentId);
          //To make sure the language file already loaded
          this.translationSerivce
            .get("'CARDS_STATUS.ERROR.TryAgain'")
            .subscribe(() => {
              this.controllers = [
                {
                  name: this.translationSerivce.instant(
                    'CARDS_STATUS.ERROR.TryAgain'
                  ),
                  action: () => {
                    this._chargeFacade.resetAuthorize();
                    this._chargeFacade.reset();

                    this._router.navigate([ROUTES_KEYS.HOME], {
                      queryParams: {
                        paymentId: this._paymentId,
                        lang: this._lang,
                        livemode: this._livemode,
                      },
                    });
                    localStorage.clear();
                    sessionStorage.clear();
                  },
                  type: 'primary',
                },
                {
                  name: this.translationSerivce.instant(
                    'CARDS_STATUS.ERROR.GoBack'
                  ),
                  action: () => {
                    this.submerchant$?.subscribe(item => {
                      if (item && item.webSite)
                        //!CHECK: the URL protocol not exist from the BE response
                        window.open(item.webSite, '_blank');
                    });
                  },
                  type: 'link',
                },
              ];
            });
        }
      });
  }
}
