import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslateModule],
  template: ` <div class="row footer">
    <div class="col-12">
      <div class="footer__content d-flex gap-3">
        <div class="d-flex">
          {{ 'FOOTER.PoweredBy' | translate }}
          <a href="https://bkn301.sm/" target="_blank" class="bkn-link ms-2"
            >BKN301</a
          >
        </div>
        |
        <div class="d-flex align-items-center gap-3">
          {{ 'FOOTER.VerifiedBy' | translate }}
          <span>
            <img src="assets/images/visa.png" alt="VISA" />
          </span>
          <span>
            <img
              src="assets/images/mastercard_id_check.svg"
              alt="master Card"
            />
          </span>
        </div>
      </div>
    </div>
  </div>`,
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {}
