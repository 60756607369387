import {createAction, props} from '@ngrx/store';
import {ResponseLink} from '../../models';
import {PaymentDto, SubmerchantDto} from '../../models/data-contracts';
import {PrimitiveSuccess} from '../../models/primitive-vm';

export const submerchant = createAction(
  '[Payment] submerchant',
  props<{id: string}>()
);

export const submerchantSuccess = createAction(
  '[Payment] submerchant Success',
  props<{submerchant?: SubmerchantDto}>()
);

export const submerchantError = createAction(
  '[Payment] submerchant Error',
  props<any>()
);

export const submerchantReset = createAction('[Payment] submerchant Reset');

export const payment = createAction(
  '[Payment] payment',
  props<{paymentId: string}>()
);

export const paymentSuccess = createAction(
  '[Payment] payment Success',
  props<{payment: PaymentDto}>()
);

export const paymentError = createAction(
  '[Payment] payment Error',
  props<any>()
);

export const paymentReset = createAction('[Payment] payment reset');

export const createPaymentLink = createAction(
  '[Payment] paymentlink create',
  props<{idLink: string}>()
);

export const createPaymentLinkSuccess = createAction(
  '[Payment] paymentlink create Success',
  props<{payment: ResponseLink}>()
);

export const createPaymentLinkError = createAction(
  '[Payment] paymentlink create Error',
  props<any>()
);

export const createPaymentLinkReset = createAction(
  '[Payment] paymentlink create Reset'
);

export const validate = createAction(
  '[Payment] validate',
  props<{id: string}>()
);

export const validateSuccess = createAction(
  '[Payment] validate Success',
  props<{response: PrimitiveSuccess<boolean>}>()
);

export const validateError = createAction(
  '[Payment] validate Error',
  props<any>()
);
