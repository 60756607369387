import {registerLocaleData} from '@angular/common';
import {Injectable} from '@angular/core';
import {Language} from '../models';

@Injectable({
  providedIn: 'root',
})
export class LocateService {
  constructor() {}

  async localeInitializer(lang: Language[]): Promise<any> {
    let promises = [];

    promises.push(
      lang.map(async ll => {
        const module = await import(
          /* @vite-ignore */
          // @ts-ignore
          `../../../../node_modules/@angular/common/locales/${ll.prefix}.mjs`
        );

        const moduleextra = await import(
          /* @vite-ignore */
          // @ts-ignore
          `../../../../node_modules/@angular/common/locales/${ll.prefix}.mjs`
        );

        return Promise.all([
          registerLocaleData(module.default, ll.prefix, moduleextra.default),
          registerLocaleData(module.default, ll.full, moduleextra.default),
        ]);
      })
    );

    return Promise.all(promises);
  }
}
