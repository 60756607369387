import {Injectable} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {filter} from 'rxjs';
import * as PaymentActions from '../actions/payment.action';
import {PaymentReducers} from '../reducers';
import {paymentSelectors} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class SubmerchantFacade {
  constructor(private _store: Store<PaymentReducers.State>) {}

  Submerchant$ = this._store.pipe(
    select(paymentSelectors.selectFirstOrDefaulMerchant),
    filter(item => !!item)
  );

  GetSubmerchant = (id: string) =>
    this._store.dispatch(PaymentActions.submerchant({id}));
}
