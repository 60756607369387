import {Pipe, PipeTransform} from '@angular/core';
import {Observable, map} from 'rxjs';
import {SettingsService} from '../services/settings.service';

@Pipe({
  name: 'cardType',
  standalone: true,
})
export class CardTypePipe implements PipeTransform {
  constructor(private readonly _settingsService: SettingsService) {}

  transform(value: string, ...args: unknown[]): Observable<string[]> {
    return this._settingsService.Get().pipe(
      map(conf => {
        let sources = conf.cards;

        if (!sources) return [];

        sources = sources.map(xx => {
          return {...xx, path: `../../../../../assets/images/${xx.path}`};
        });

        if (!value) return sources.map(xx => xx.path);
        const val = +value.trim().replaceAll(' ', '');
        if (isNaN(val)) return sources.map(xx => xx.path);

        const allCardMatches = value?.length
          ? sources.filter(xx => value.match(xx.regex)).map(xx => xx.path)
          : sources.map(xx => xx.path);

        return allCardMatches.length ? allCardMatches : [];

        // { @"^4[0-9]{12}(?:[0-9]{3})?$","VISA"},
        //         { @"^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$","MASTERCARD"},
        //         { @"^3[47][0-9]{13}$","AMEX"},
        //         { @"^6(?:011|5[0-9]{2})[0-9]{12}$","DISCOVER"},
        //         { @"^(?:2131|1800|35\d{3})\d{11}$","JCB"}
      })
    );
  }
}
