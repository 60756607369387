import {CommonModule} from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {environment} from '@env/environment';
import {PaymentLight} from '@models/index';
import {TranslateModule} from '@ngx-translate/core';
import {ThemeFacade} from '@store/index';
import {ReplaceUri} from '@utils/index';

@Component({
  selector: 'app-counter-redirect',
  templateUrl: './counter-redirect.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class CounterRedirectComponent implements OnDestroy {
  _themeFacade = inject(ThemeFacade);

  @Input() paymentLight?: PaymentLight;
  @Input() isToRedirect: boolean = false;
  subscriptions: any[] = [];
  ngOnInit(): void {
    const sub = this._themeFacade.theme$.subscribe(res => {
      if (res) {
        setTimeout(() => {
          if (this.paymentLight) {
            const thankYouPageUrl = res.thankYouPageUrl;
            const returnUrl =
              this.paymentLight.metadata &&
              this.paymentLight.metadata['returnUrl']
                ? this.paymentLight.metadata['returnUrl']
                : thankYouPageUrl
                ? thankYouPageUrl
                : this.paymentLight.webSite || '';

            if (returnUrl) {
              (window as any).location.href = this.chekForProtocol(
                ReplaceUri(returnUrl, this.paymentLight.id)
              );
            }
          }
        }, environment.TIME_OUT_REDIRECT);
      }
    });

    this.subscriptions.push(sub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['paymentLight'] && changes['paymentLight'].currentValue) {
      const sub = this._themeFacade.theme$.subscribe(res => {
        if (res) {
          const thankYouPageUrl = res.thankYouPageUrl;

          const returnUrl =
            changes['paymentLight'].currentValue.metadata &&
            changes['paymentLight'].currentValue.metadata['returnUrl']
              ? changes['paymentLight'].currentValue.metadata['returnUrl']
              : thankYouPageUrl
              ? thankYouPageUrl
              : changes['paymentLight'].currentValue.webSite || '';

          if (returnUrl) {
            setTimeout(() => {
              (window as any).location.href = this.chekForProtocol(
                ReplaceUri(returnUrl, changes['paymentLight'].currentValue.id)
              );
            }, environment.TIME_OUT_REDIRECT);
          }
        }
      });
      this.subscriptions.push(sub);
    }
  }

  private chekForProtocol(url: string) {
    let newurl = url;

    if (!url) return '';

    if (!url.startsWith('https') && !url.startsWith('http')) {
      newurl = `https://${url}`;
    }

    return newurl;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
