import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {URL_PARAMS} from '@constant/index';
import {Settings, SubmerchantDto} from '@models/index';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateModule} from '@ngx-translate/core';
import {Frame3dsService} from '@services/frame3ds.service';
import {SettingsService} from '@services/settings.service';
import {SubmerchantFacade} from '@store/facade/submerchant-facade.service';
import {paramsToLower} from '@utils/query-params-utils';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {Observable} from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-bkn-secure',
  standalone: true,
  imports: [CommonModule, NzSpinModule, TranslateModule],
  template: `<nz-spin
    [nzSpinning]="!ds2exist"
    [nzTip]="'LOADING.Title' | translate"
  >
    <div class="row pt-5 justify-content-center">
      <div class="col-md-6">
        <iframe #frame3ds2 width="600" height="600" [src]="iauthUrl"></iframe>
      </div>
    </div>
  </nz-spin>`,
  styleUrl: './bkn-secure.component.scss',
})
export class BknSecureComponent implements OnInit, AfterViewInit {
  subMerchant$?: Observable<SubmerchantDto | null | undefined>;
  configuration?: Observable<Settings>;
  isSpinning: boolean = true;
  ds2exist: boolean = false;

  _configurationService = inject(SettingsService);
  _submerchantfacade = inject(SubmerchantFacade);
  sanitizer = inject(DomSanitizer);
  _frameService = inject(Frame3dsService);
  _activatedRouter = inject(ActivatedRoute);

  @ViewChild('frame3ds2', {static: true})
  frame3ds2!: ElementRef<HTMLIFrameElement>;
  iauthUrl?: SafeResourceUrl;

  constructor() {
    // alert('BknSecureComponent');
    this.configuration = this._configurationService.Get();
    this._frameService.iframeCloseEvent.subscribe(() => {
      this.frame3ds2?.nativeElement.remove();

      this.ds2exist = false;
    });

    this.subMerchant$ = this._submerchantfacade.Submerchant$.pipe(
      untilDestroyed(this)
    );
  }

  ngAfterViewInit(): void {
    this.iauthUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this._frameService.GetOtpUri()
    );

    if (this.frame3ds2?.nativeElement?.src)
      this.frame3ds2.nativeElement.src = this._frameService.GetOtpUri();
  }

  ngOnInit(): void {
    this.ds2exist = true;
    this.iauthUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      // .pipe(untilDestroyed(this), distinctUntilChanged(), skip(1))
      .subscribe(item => {
        const res = paramsToLower(item);

        const paymentId = res[URL_PARAMS.PAYMENT_ID];
        this._submerchantfacade.GetSubmerchant(paymentId);
      });
  }
}
