import {CommonModule} from '@angular/common';
import {Component, inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {URL_PARAMS} from '@constant/index';
import {Settings, SubmerchantDto} from '@models/index';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateModule} from '@ngx-translate/core';
import {CookiesService} from '@services/cookie.service';
import {NavigationService} from '@services/navigation.service';
import {SettingsService} from '@services/settings.service';
import {SubmerchantFacade} from '@store/facade/submerchant-facade.service';
import {paramsToLower} from '@utils/query-params-utils';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {Observable, filter} from 'rxjs';
import {BknPaymentFormComponent} from '../../components/bkn-payment-form/bkn-payment-form.component';
import {CounterRedirectComponent} from '../../components/counter-redirect/counter-redirect.component';
import {StatusCardComponent} from '../../components/status-card/status-card.component';

@UntilDestroy()
@Component({
  selector: 'app-thank-you',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzRadioModule,
    FormsModule,
    CounterRedirectComponent,
    BknPaymentFormComponent,
    StatusCardComponent,
  ],
  template: `<app-status-card
      [icon]="'check'"
      [status]="'success'"
      [title]="'CARDS_STATUS.SUCCESS.Title' | translate"
      [description]="'CARDS_STATUS.SUCCESS.Description' | translate"
    >
    </app-status-card>

    <app-counter-redirect
      [isToRedirect]="isToRedirect"
      [paymentLight]="paymentLight"
    ></app-counter-redirect>`,

  styles:
    ':host { display: flex; justify-content: center; align-items: center; height: 100%; }',
})
export class ThankYouComponent {
  gatewayMessage: string = '';
  subMerchant$?: Observable<SubmerchantDto | null | undefined>;
  paymentLight?: SubmerchantDto;
  isToRedirect: boolean = false;
  configuration: Observable<Settings>;
  _submerchantFacade = inject(SubmerchantFacade);
  _activatedRouter = inject(ActivatedRoute);
  _cookieService = inject(CookiesService);
  _configurationService = inject(SettingsService);
  _navigationService = inject(NavigationService);

  constructor() {
    this.configuration = this._configurationService.Get();
    this._submerchantFacade.Submerchant$.pipe(
      untilDestroyed(this),
      filter(item => !!item)
    ).subscribe(item => {
      this.paymentLight = item;

      this.isToRedirect =
        !!this.paymentLight &&
        ((!!this.paymentLight.metadata &&
          !!this.paymentLight.metadata['returnUrl']) ||
          !!this.paymentLight.webSite);
    });
  }

  ngOnInit(): void {
    this._navigationService.showFoter.emit(false);

    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(item => {
        const res = paramsToLower(item);

        const paymentId = res[URL_PARAMS.PAYMENT_ID];

        if (paymentId) this._submerchantFacade.GetSubmerchant(paymentId);
      });
  }

  ngOnDestroy() {
    // this._utilsService.clearLocalStorage();
    //localStorage.clear();
    sessionStorage.clear();
    this._cookieService.deleteAllCookies();
  }
}
