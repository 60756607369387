import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PaymentErrorControllers} from '@models/index';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss'],
  standalone: true,
  imports: [CommonModule, NzButtonModule, NzIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCardComponent {
  @Input() status: 'success' | 'error' | 'loading' = 'loading';
  @Input() icon: string | 'loading' | 'check' | 'close' = 'loading';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() controllers?: PaymentErrorControllers[];
}
