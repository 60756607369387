<div class="row pt-md-5 justify-content-center">
  <div class="col-md-6 px-0">
    <div class="card border-0">
      <div class="row text-center align-items-center">
        <div class="col-12 my-4">
          <span
            nz-icon
            nzType="loading"
            class="primary-color fs-100"
            [nzSpin]="true"
          ></span>
        </div>
        <div class="col-12 mt-4">
          <h6 class="loading-description">
            {{ 'EXTERNAL_PAYMENT.LoadingText' | translate }}
            <br />
            <span
              ><a class="primary-link ms-2" [href]="redirectUri">{{
                'EXTERNAL_PAYMENT.ClickHere' | translate
              }}</a></span
            >
            {{ 'EXTERNAL_PAYMENT.RedirectInfo' | translate }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
