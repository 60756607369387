import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Personalization} from '@models/theme.interface';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _url = (id: string) =>
    `${environment.API_URL}Payments/${id}/submerchant`;

  constructor(private _http: HttpClient) {}

  getTheme(id: string): Observable<Personalization> {
    return this._http.get<any>(this._url(id)).pipe(
      map(res => {
        return {
          logo: res.personalization.logo,
          payButtonColor: res.personalization.payButtonColor,
          payButtonTextColor: res.personalization.payButtonTextColor,
          payBackgroundColor: res.personalization.payBackgroundColor,
          thankYouPageUrl: res.personalization.thankYouPageUrl,
        };
      })
    );
  }
}
