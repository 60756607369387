<div class="payment-card">
  <!-- FORM -->
  <app-bkn-payment-form
    [subMerchant]="subMerchant | async"
    [payment]="payment$ | async"
    (submitEvent)="onSubmit($event)"
    [isSpinning]="isLoading$ | async"
    [isChangingPaymentMethod]="isChangingPaymentMethod"
  ></app-bkn-payment-form>
  <!-- / FORM -->

  <iframe #frame class="d-none" [src]="iframeUrl"></iframe>
</div>
