import {Personalization} from '@models/theme.interface';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {ThemeActions} from '../actions';

export const themeFeatureKey = 'theme';

export interface State extends EntityState<Personalization> {
  isLoading: boolean;
  error?: any;
  theme: Personalization | undefined;
}

export const adapter: EntityAdapter<Personalization> =
  createEntityAdapter<Personalization>();

export const initialState: State = adapter.getInitialState({
  error: undefined,
  theme: undefined,
  isLoading: false,
});

export const ThemeReducer = createReducer(
  initialState,

  on(ThemeActions.getMerchantTheme, (state, {}) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(ThemeActions.getMerchantThemeError, (state, {error}) => ({
    ...state,
    error,
    isLoading: false, //true
  })),
  on(ThemeActions.getMerchantThemeSuccess, (state, {theme}) => ({
    ...state,
    theme,
    isLoading: false, //true
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return ThemeReducer(state, action);
}

export const {selectAll, selectIds, selectEntities, selectTotal} =
  adapter.getSelectors();
