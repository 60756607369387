import {Personalization} from '@models/theme.interface';
import {createAction, props} from '@ngrx/store';

export const getMerchantTheme = createAction(
  '[customization] get Theme',
  props<{id: string}>()
);

export const getMerchantThemeSuccess = createAction(
  '[customization] get theme Success',
  props<{theme?: Personalization}>()
);

export const getMerchantThemeError = createAction(
  '[customization] get theme error',
  props<any>()
);
