import {Injectable} from '@angular/core';
import * as Forge from 'node-forge';

@Injectable({
  providedIn: 'root',
})
export class RSAHelper {
  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0p8hhWPSLIbPS43RA8+L
  VTIPaekG13Z6ILhOog1StOlNQ1wFjJ0/menn693HYUO9N1hkiaA7ZtdRQsDbeQP8
  nyxUEs+GIIPJb6UJIeLVmeOAqdlk6/Sh2Dfq9DWqm8oXAcIJNceef5ys2Hr1MZs5
  FPJ8AmKuoRy+5FE6ZPx0BlMIjApULYx21JqTc8qFEoNxwemPQdtrgy0iCdi69pRj
  sIT1P7sSr1Twp6x7tYhxkA0pkKcYf/tUXZzYAYcKvimop4IXrLhxItqx7Gh7mLQ4
  DxwYJ5ZQY2Wf7VuatABU1r7VWq0jpXqRVtNiLQEW+ROpTzAdZPe2on4MXAGiEpmw
  eQ7eJRRBGT3s1JFXSp/geTe7QYmNKHHFX9v6PFXVNwCFFF8U1NGQPeA6ytwzdwQb
  RzV0rtvS7TOaTpBK35Md+/Inbdq+vh0UmUBClZRX/Sk9YSsegei+mxQnVziymSwU
  rj75NXaTLNBBMHOJqsa7AcaG2eMBBy+YopDN3XG6H3oQeDqcNct4ThV73/YBP7AW
  cikwfvixgK53nxvIqQ3TYuKIoxVRzKDUImq6lCxEFxBUAsivyEqmBvJ1QKyHPaQj
  qc6ebE2SmFwwz3vAZ/1I3WQq2NuFpIW2DwoZJKyHqaUHge3MWs0ahDlmR16aMx4Q
  1aryjb4+6ljJiVwBmnBDRmkCAwEAAQ==
  -----END PUBLIC KEY-----`;

  constructor() {}

  encryptWithPublicKey(valueToEncrypt: string): string {
    const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
    return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
  }
}
