import { createAction, props } from '@ngrx/store';

export const loading = createAction('[Loading] loading');

export const hide = createAction('[Loading] hide');

export const loader = createAction(
  '[Loading] loader',
  props<{ loader: boolean }>()
);
