<div class="payment-card">
  <div class="loading" *ngIf="isPaying">
    <nz-spin nzSimple [nzSpinning]="isPaying"></nz-spin>
  </div>
  <div class="form-container">
    <div class="px-0">
      <div class="error-message m-0">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="errorMessage"
          [innerText]="errorMessage"
        ></div>
      </div>

      <form *ngIf="paymentForm" [formGroup]="paymentForm" (ngSubmit)="pay()">
        <div class="card">
          <div
            class="card-title d-flex justify-content-between align-items-center mb-0"
          >
            <h6>
              {{ 'BKN.PAYMENT_FORM.CardInformation' | translate }}
            </h6>
            <span class="d-flex gap-1">
              <img src="assets/images/lock.svg" alt="secure" />
              <p class="secure-label m-0">
                {{ 'BKN.PAYMENT_FORM.Secure' | translate }}
              </p>
            </span>
          </div>
          <!-- Card holder name  -->
          <div class="mb-1">
            <label for="cardHolderName" class="form-label">{{
              'BKN.PAYMENT_FORM.CardHolderName' | translate
            }}</label>
            <nz-input-group
              [ngClass]="{
                'error-input':
                  paymentForm.get('cardHolderName')!.invalid &&
                  paymentForm.get('cardHolderName')!.errors &&
                  (paymentForm.get('cardHolderName')!.dirty ||
                    paymentForm.get('cardHolderName')!.touched)
              }"
              class="input-border form-control d-flex input-field input-group-container"
            >
              <input
                nz-input
                type="text"
                class="input-field form-control"
                tabindex="1"
                minlength="3"
                maxlength="40"
                [placeholder]="
                  'BKN.PAYMENT_FORM.CardHolderNamePlaceholder' | translate
                "
                (blur)="setfocus(false, 'cardHolderName')"
                (focus)="setfocus(true, 'cardHolderName')"
                (input)="onAutoFillcheckCH($event)"
                [class.valid]="
                  paymentForm.get('cardHolderName')!.valid &&
                  (paymentForm.get('cardHolderName')!.dirty ||
                    paymentForm.get('cardHolderName')!.touched)
                "
                [class.invalid]="
                  paymentForm.get('cardHolderName')!.invalid &&
                  (paymentForm.get('cardHolderName')!.dirty ||
                    paymentForm.get('cardHolderName')!.touched)
                "
                formControlName="cardHolderName"
                id="cardHolderName"
                #fullname
              />
            </nz-input-group>
            <div
              *ngIf="
                paymentForm.get('cardHolderName')!.invalid &&
                paymentForm.get('cardHolderName')!.errors &&
                (paymentForm.get('cardHolderName')!.dirty ||
                  paymentForm.get('cardHolderName')!.touched)
              "
            >
              <small
                class="text-danger fs-12"
                *ngIf="
                  paymentForm.get('cardHolderName')?.hasError('pattern') ||
                  paymentForm.get('cardHolderName')?.hasError('minlength')
                "
              >
                {{ 'ERRORS.card-holder-valid' | translate }}
              </small>
            </div>
          </div>

          <!-- credit Card Number -->
          <div class="mb-1">
            <label for="creditCardNumber" class="form-label">
              {{ 'BKN.PAYMENT_FORM.CardInformation' | translate }}
            </label>
            <nz-input-group
              [nzAddOnAfter]="addOnAfterTemplate"
              [ngClass]="{
                'error-input':
                  paymentForm.get('creditCardNumber')!.invalid &&
                  paymentForm.get('creditCardNumber')!.errors &&
                  (paymentForm.get('creditCardNumber')!.dirty ||
                    paymentForm.get('creditCardNumber')!.touched)
              }"
              class="input-border form-control d-flex input-field input-group-container"
            >
              <input
                (blur)="setfocus(false, 'creditCardNumber')"
                (focus)="setfocus(true, 'creditCardNumber')"
                nz-input
                tabindex="2"
                type="text"
                inputmode="numeric"
                pattern="[0-9 ]*"
                maxlength="24"
                [inputMask]="onlyNumbersMask"
                placeholder="1234 1234 1234 1234"
                (input)="onAutoFillcheckCC($event)"
                [value]="
                  paymentForm.get('creditCardNumber')?.value
                    | cardNumberStyle
                    | checkCCN
                "
                [class.valid]="
                  paymentForm.get('creditCardNumber')!.valid &&
                  (paymentForm.get('creditCardNumber')!.dirty ||
                    paymentForm.get('creditCardNumber')!.touched)
                "
                [class.invalid]="
                  paymentForm.get('creditCardNumber')!.invalid &&
                  (paymentForm.get('creditCardNumber')!.dirty ||
                    paymentForm.get('creditCardNumber')!.touched)
                "
                formControlName="creditCardNumber"
                id="creditCardNumber"
                class="input-field form-control"
                #ccnumber
              />
            </nz-input-group>

            <ng-template #addOnAfterTemplate class="p-0">
              <span *ngIf="paymentForm.get('creditCardNumber')!.value">
                <img
                  *ngFor="
                    let source of paymentForm.get('creditCardNumber')!.value!
                      | cardType
                      | async
                  "
                  class="mx-1"
                  [src]="source"
                />
              </span>
            </ng-template>

            <div
              *ngIf="
                paymentForm.get('creditCardNumber')!.invalid &&
                paymentForm.get('creditCardNumber')!.errors &&
                (paymentForm.get('creditCardNumber')!.dirty ||
                  paymentForm.get('creditCardNumber')!.touched)
              "
            >
              <small
                class="text-danger fs-12"
                *ngIf="
                  paymentForm.get('creditCardNumber')?.hasError('required')
                "
              >
                {{ 'ERRORS.field-required' | translate }}
              </small>

              <small
                class="text-danger fs-12"
                *ngIf="
                  paymentForm.get('creditCardNumber')?.hasError('maxlength')
                "
              >
                {{ 'ERRORS.card-number-valid' | translate }}
              </small>
              <small
                class="text-danger fs-12"
                *ngIf="
                  paymentForm.get('creditCardNumber')?.hasError('luhnCheck')
                "
              >
                {{ 'ERRORS.card-number-invalid' | translate }}
              </small>
              <small
                class="text-danger fs-12"
                *ngIf="
                  paymentForm
                    .get('creditCardNumber')!
                    .hasError('checkIfCardIsTest')
                "
              >
                {{ 'ERRORS.card-number-test-mode-invalid' | translate }}
              </small>
            </div>
          </div>
          <!-- cardHolderName -->

          <div class="row align-items-end">
            <!-- expiredMonth -->
            <div class="col-6 form-group pe-0" formGroupName="expirationDate">
              <label class="form-label">
                {{ 'BKN.PAYMENT_FORM.ExpiryDate' | translate }}
              </label>
              <div class="d-flex align-items-center">
                <nz-input-group
                  nzCompact
                  [ngClass]="{
                    'error-input':
                      ((paymentForm.get('expirationDate')?.get('expiredMonth'))!
                        .invalid &&
                        (paymentForm
                          .get('expirationDate')
                          ?.get('expiredMonth'))!.errors &&
                        ((paymentForm
                          .get('expirationDate')
                          ?.get('expiredMonth'))!.dirty ||
                          (paymentForm
                            .get('expirationDate')
                            ?.get('expiredMonth'))!.touched)) ||
                      ((paymentForm.get('expirationDate')?.get('expiredYear'))!
                        .invalid &&
                        (paymentForm.get('expirationDate')?.get('expiredYear'))!
                          .errors &&
                        ((paymentForm
                          .get('expirationDate')
                          ?.get('expiredYear'))!.dirty ||
                          (paymentForm
                            .get('expirationDate')
                            ?.get('expiredYear'))!.touched))
                  }"
                  class="input-border form-control d-flex input-field input-group-container"
                >
                  <input
                    nz-input
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    id="expiredMonth"
                    tabindex="3"
                    class="input-field form-control"
                    minlength="2"
                    maxlength="2"
                    [inputMask]="onlyNumbersMask"
                    placeholder="MM"
                    (input)="onAutoFillEventMonth($event)"
                    (blur)="setfocus(false, 'expiredMonth')"
                    (focus)="setfocus(true, 'expiredMonth')"
                    [ngClass]="{
                      'error-input':
                        (paymentForm
                          .get('expirationDate')
                          ?.get('expiredMonth'))!.invalid &&
                        (paymentForm
                          .get('expirationDate')
                          ?.get('expiredMonth'))!.errors &&
                        ((paymentForm
                          .get('expirationDate')
                          ?.get('expiredMonth'))!.dirty ||
                          (paymentForm
                            .get('expirationDate')
                            ?.get('expiredMonth'))!.touched)
                    }"
                    [class.valid]="
                      (paymentForm.get('expirationDate')?.get('expiredMonth'))!
                        .valid &&
                      ((paymentForm.get('expirationDate')?.get('expiredMonth'))!
                        .dirty ||
                        (paymentForm
                          .get('expirationDate')
                          ?.get('expiredMonth'))!.touched)
                    "
                    [class.invalid]="
                      (paymentForm.get('expirationDate')?.get('expiredMonth'))!
                        .invalid &&
                      ((paymentForm.get('expirationDate')?.get('expiredMonth'))!
                        .dirty ||
                        (paymentForm
                          .get('expirationDate')
                          ?.get('expiredMonth'))!.touched)
                    "
                    formControlName="expiredMonth"
                    #month
                  />
                  <span class="me-2">/</span>
                  <input
                    nz-input
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    id="expiredYear"
                    class="input-field form-control border-0"
                    tabindex="4"
                    minlength="2"
                    placeholder="YY"
                    maxlength="2"
                    [inputMask]="onlyNumbersMask"
                    (blur)="setfocus(false, 'expiredYear')"
                    (focus)="setfocus(true, 'expiredYear')"
                    (input)="onAutoFillEventYear($event)"
                    formControlName="expiredYear"
                    #year
                  />
                </nz-input-group>
              </div>
            </div>
            <!-- securityCode -->

            <div class="col-6 form-group input-group-container">
              <label for="security-code" class="form-label">{{
                'BKN.PAYMENT_FORM.CVV' | translate
              }}</label>
              <nz-input-group
                id="securityCodeInputGroup"
                [nzAddOnAfter]="suffixIconCvv"
                [ngClass]="{
                  'error-input':
                    paymentForm.get('securityCode')!.invalid &&
                    paymentForm.get('securityCode')!.errors &&
                    (paymentForm.get('securityCode')!.dirty ||
                      paymentForm.get('securityCode')!.touched)
                }"
                class="input-border input-field form-control"
              >
                <input
                  nz-input
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  minlength="3"
                  maxlength="4"
                  id="securityCode"
                  class="input-field form-control"
                  value=""
                  placeholder="CVV"
                  tabindex="5"
                  [inputMask]="onlyNumbersMask"
                  (blur)="setfocus(false, 'securityCode')"
                  (focus)="setfocus(true, 'securityCode')"
                  (input)="onAutoFillEventCvv($event)"
                  [class.valid]="
                    paymentForm.get('securityCode')!.valid &&
                    (paymentForm.get('securityCode')!.dirty ||
                      paymentForm.get('securityCode')!.touched)
                  "
                  [class.invalid]="
                    paymentForm.get('securityCode')!.invalid &&
                    (paymentForm.get('securityCode')!.dirty ||
                      paymentForm.get('securityCode')!.touched)
                  "
                  formControlName="securityCode"
                  #cvv
                />
                <ng-template #suffixIconCvv>
                  <img
                    nz-tooltip
                    [nzTooltipTitle]="'BKN.PAYMENT_FORM.CVV_HINT' | translate"
                    src="../../../../../assets/images/help-circle.svg"
                  />
                </ng-template>
              </nz-input-group>
            </div>
            <div class="conatiner-fluid">
              <div class="row align-items-start">
                <div class="col-6 d-flex">
                  @if(
                  ((paymentForm.get('expirationDate')?.get('expiredMonth')?.touched||paymentForm.get('expirationDate')?.get('expiredMonth')?.dirty)
                  ||
                  (paymentForm.get('expirationDate')?.get('expiredYear')?.touched||paymentForm.get('expirationDate')?.get('expiredYear')?.dirty))
                  ){ @if (
                  ((paymentForm.get('expirationDate')?.get('expiredMonth')?.hasError('required'))
                  || (
                  paymentForm.get('expirationDate')?.get('expiredMonth')?.valid
                  &&(paymentForm.get('expirationDate')?.get('expiredYear')?.hasError('required')))))
                  {
                  <small class="text-danger fs-12">
                    {{ 'ERRORS.field-required' | translate }}
                  </small>
                  } @if (
                  (paymentForm.get('expirationDate')?.get('expiredMonth')?.hasError('max'))
                  ||
                  (paymentForm.get('expirationDate')?.get('expiredMonth')?.valid
                  &&(paymentForm.get('expirationDate')?.get('expiredYear')?.hasError('min'))))
                  {
                  <small class="text-danger fs-12">
                    {{ 'ERRORS.ExpirationDateValidation' | translate }}
                  </small>
                  } }
                </div>
                <!-- securityCode error -->
                <div class="col-6 form-group">
                  <div
                    *ngIf="
                      paymentForm.get('securityCode')?.touched &&
                      paymentForm?.hasError('cvvUnipayRequired')
                    "
                  >
                    <small class="text-danger fs-12">
                      {{ 'ERRORS.field-required' | translate }}
                    </small>
                    <small
                      class="text-danger fs-12"
                      *ngIf="
                        paymentForm.get('securityCode')?.hasError('pattern')
                      "
                    >
                      {{ 'ERRORS.cvv-valid' | translate }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- HIDE FOR BKN301DEV-6855 TASK -->
        <div *ngIf="false">
          <div
            class="row align-items-center mt-3"
            *ngIf="payment?.subscriptionId && showEmailField"
          >
            <!-- subscriberEmail -->
            <div class="col-1">
              <div class="check-container d-flex align-items-center">
                <input
                  id="showEmail"
                  type="checkbox"
                  formControlName="showSubscriberEmail"
                />
                <label for="showEmail" class="checkBox"></label>
              </div>
            </div>
            <ng-container>
              <div class="col-10">
                <label class="fs-14">{{
                  'BKN.PAYMENT_FORM.CheckboxLabel' | translate
                }}</label>
              </div>
              <div
                class="col-12"
                *ngIf="paymentForm.get('showSubscriberEmail')?.value"
              >
                <nz-input-group
                  [ngClass]="{
                    'error-input':
                      paymentForm.get('subscriberEmail')!.invalid &&
                      paymentForm.get('subscriberEmail')!.errors &&
                      (paymentForm.get('subscriberEmail')!.dirty ||
                        paymentForm.get('subscriberEmail')!.touched)
                  }"
                  class="input-border"
                >
                  <!-- <label for="cardHolderName" class="form-label fs-12 m-0">{{
                    'BKN.PAYMENT_FORM.SubscriberEmail' | translate
                  }}</label> -->
                  <input
                    nz-input
                    type="text"
                    class="input-field form-control fs-14 text-uppercase"
                    tabindex="6"
                    minlength="3"
                    maxlength="40"
                    [placeholder]="
                      'BKN.PAYMENT_FORM.SubscriberEmail' | translate
                    "
                    (blur)="setfocus(false, 'subscriberEmail')"
                    (focus)="setfocus(true, 'subscriberEmail')"
                    class="input-field form-control mt-2 mb-2"
                    [class.valid]="
                      paymentForm.get('subscriberEmail')!.valid &&
                      (paymentForm.get('subscriberEmail')!.dirty ||
                        paymentForm.get('subscriberEmail')!.touched)
                    "
                    [class.invalid]="
                      paymentForm.get('subscriberEmail')!.invalid &&
                      (paymentForm.get('subscriberEmail')!.dirty ||
                        paymentForm.get('subscriberEmail')!.touched)
                    "
                    formControlName="subscriberEmail"
                    id="subscriberEmail"
                    #subscriberEmail
                  />
                </nz-input-group>

                <div
                  *ngIf="
                    paymentForm.get('subscriberEmail')!.invalid &&
                    paymentForm.get('subscriberEmail')!.errors &&
                    (paymentForm.get('subscriberEmail')!.dirty ||
                      paymentForm.get('subscriberEmail')!.touched)
                  "
                >
                  <small
                    class="text-danger fs-12"
                    *ngIf="
                      paymentForm.get('subscriberEmail')?.hasError('pattern')
                    "
                  >
                    {{ 'ERRORS.card-holder-valid' | translate }}
                  </small>
                </div>
              </div>
            </ng-container>
            <!-- subscriberMobile -->
            <!-- <div class="col-12 mb-4">
              <nz-input-group
                [ngClass]="{
                  'error-input':
                    paymentForm.get('subscriberMobile')!.invalid &&
                    paymentForm.get('subscriberMobile')!.errors &&
                    (paymentForm.get('subscriberMobile')!.dirty ||
                      paymentForm.get('subscriberMobile')!.touched)
                }"
                class="subscriber-input-border"
              >
                <input
                  nz-input
                  type="text"
                  class="input-field form-control fs-14 subscriber-input"
                  tabindex="1"
                  minlength="3"
                  maxlength="40"
                  (blur)="setfocus(false, 'subscriberMobile')"
                  (focus)="setfocus(true, 'subscriberMobile')"
                  [class.valid]="
                    paymentForm.get('subscriberMobile')!.valid &&
                    (paymentForm.get('subscriberMobile')!.dirty ||
                      paymentForm.get('subscriberMobile')!.touched)
                  "
                  [class.invalid]="
                    paymentForm.get('subscriberMobile')!.invalid &&
                    (paymentForm.get('subscriberMobile')!.dirty ||
                      paymentForm.get('subscriberMobile')!.touched)
                  "
                  formControlName="subscriberMobile"
                  id="subscriberMobile"
                  #subscriberMobile
                  [placeholder]="
                    'BKN.PAYMENT_FORM.SubscriberPhone' | translate
                  "
                />
              </nz-input-group>
            </div> -->
          </div>
        </div>

        <div class="row justify-content-center payment-container">
          <div class="col-12">
            <app-btn-submerchant-pay
              [isPaying]="isPaying"
              [btnType]="'submit'"
              [tabindex]="6"
              [subMerchant]="subMerchant"
              [label]="'BKN.PAYMENT_FORM.PayNow'"
              [disable]="!paymentForm.valid"
            ></app-btn-submerchant-pay>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
