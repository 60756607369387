import {CommonModule} from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  BknPaymentForm,
  BknPaymentFormControls,
  PaymentDto,
  SubmerchantDto,
} from '@models/index';
import {InputMaskModule, createMask} from '@ngneat/input-mask';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateModule} from '@ngx-translate/core';
import {CardNumberStylePipe} from '@pipes/card-number-style.pipe';
import {CardTypePipe} from '@pipes/card-type.pipe';
import {CheckCCNPipe} from '@pipes/check-ccn.pipe';
import {SettingsService} from '@services/settings.service';
import {
  CVVunipayValidation,
  cardTestValidator,
  luhnValidator,
} from '@utils/index';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzSpinComponent} from 'ng-zorro-antd/spin';
import {Observable, filter, map} from 'rxjs';
import {BtnSubmerchantPayComponent} from '../../layouts/btn-submerchant-pay/btn-submerchant-pay.component';
@UntilDestroy()
@Component({
  selector: 'app-bkn-payment-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NzFormModule,
    NzInputModule,
    InputMaskModule,
    CardNumberStylePipe,
    CheckCCNPipe,
    CardTypePipe,
    BtnSubmerchantPayComponent,
    NzToolTipModule,
    NzSpinComponent,
    NzCheckboxModule,
  ],
  templateUrl: './bkn-payment-form.component.html',
  styleUrl: './bkn-payment-form.component.scss',
})
export class BknPaymentFormComponent {
  @Input() subMerchant?: SubmerchantDto | null | undefined;
  @Input() payment?: PaymentDto | null | undefined;
  @Input() isSpinning?: boolean | null = false;
  @Input() isChangingPaymentMethod?: boolean = false;
  @Input() embeded: boolean = false;
  @Input() embedTheme: 'light' | 'dark' = 'light';
  errorMessage: string = '';
  isPaying: boolean = false;

  paymentForm: FormGroup<BknPaymentFormControls>;

  cards$: Observable<string[]>;

  showEmailField: boolean = true;

  isOccupied = {
    cardHolderName: false,
    creditCardNumber: false,
    expiredMonth: false,
    expiredYear: false,
    securityCode: false,
    subscriberEmail: false,
    subscriberMobile: false,
  };

  _settingService = inject(SettingsService);

  @Output() submitEvent: EventEmitter<BknPaymentForm> = new EventEmitter();

  @ViewChild('ccnumber') ccnumberElement?: ElementRef;
  @ViewChild('fullname') fullnameElement?: ElementRef;
  @ViewChild('month') monthElement?: ElementRef;
  @ViewChild('year') yearElement?: ElementRef;
  @ViewChild('cvv') cvvElement?: ElementRef;

  @ViewChild('subscriberEmail') subscriberEmail?: ElementRef;
  @ViewChild('subscriberMobile') subscriberMobile?: ElementRef;

  onlyNumbersMask = createMask<string>({
    regex: '^[0-9 ]+$',
    placeholder: '',
  });

  darkBackgroundColor: string = '#000000';
  lightBackgroundColor: string = '#ffffff';

  constructor(private fb: FormBuilder) {
    this.showEmailField =
      !(
        this.subMerchant?.metadata && !!this.subMerchant?.metadata['externalId']
      ) || true;
    this.paymentForm = this.fb.group<BknPaymentFormControls>(
      {
        cardHolderName: new FormControl<string | null>('', {
          validators: [
            Validators.pattern('^[A-Za-zñÑáéèíóúÁÉÍÓÚ ]+$'),
            Validators.maxLength(40),
          ],
        }),
        creditCardNumber: new FormControl<string | null>('', {
          validators: [
            Validators.required,
            Validators.minLength(13),
            Validators.maxLength(24),
            luhnValidator(),
            cardTestValidator(),
          ],
        }),
        securityCode: new FormControl<string | null>('', {
          validators: [
            Validators.pattern('[0-9]{3,4}'),
            Validators.minLength(2),
            Validators.maxLength(4),
          ],
        }),
        expirationDate: new FormGroup(
          {
            expiredMonth: new FormControl<string | null>('', {
              validators: [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(2),
                Validators.max(12),
              ],
            }),
            expiredYear: new FormControl<string | null>('', {
              validators: [
                Validators.minLength(2),
                Validators.maxLength(2),
                Validators.required,
                Validators.min(new Date().getFullYear() - 2000),
              ],
            }),
          },
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(2),
          ]
        ),
        subscriberEmail: new FormControl<string | null>('', {
          validators: [Validators.email],
        }),
        subscriberMobile: new FormControl<string | null>('', {
          validators: [],
        }),
        fullName: new FormControl<string | null>('', {
          validators: [],
        }),
        emailAddress: new FormControl<string | null>('', {
          validators: [Validators.email],
        }),
        mobileNumber: new FormControl<string | null>('', {
          validators: [],
        }),
        showSubscriberEmail: new FormControl<boolean | null>(false),
      },
      {
        validators: [CVVunipayValidation()],
      }
    );

    this.cards$ = this._settingService
      .Get()
      .pipe(
        map(xx =>
          xx.cards
            ? xx.cards.map(xx => `../../../../../assets/images/${xx.path}`)
            : []
        )
      );

    this.paymentForm
      .get('expiredYear')
      ?.valueChanges.pipe(
        filter(
          // @ts-ignore
          (value: string | null) => value?.replaceAll(' ', '').length >= 2
        )
      )
      .subscribe(() => this.cvvElement?.nativeElement.focus());

    this.paymentForm
      .get('expiredMonth')
      ?.valueChanges.pipe(
        filter(
          // @ts-ignore
          (value: string | null) => value?.replaceAll(' ', '').length >= 2
        )
      )
      .subscribe(() => this.yearElement?.nativeElement.focus());

    this.paymentForm
      .get('creditCardNumber')
      ?.valueChanges.pipe(
        filter(
          // @ts-ignore
          (value: string | null) => value?.replaceAll(' ', '').length >= 19
        )
      )
      .subscribe(() => this.monthElement?.nativeElement.focus());
  }

  setfocus(isfocus: boolean, key: string) {
    switch (key) {
      case 'creditCardNumber':
        this.isOccupied.creditCardNumber = isfocus;
        break;
      case 'cardHolderName':
        this.isOccupied.cardHolderName = isfocus;
        break;
      case 'expiredMonth':
        this.isOccupied.expiredMonth = isfocus;
        break;
      case 'expiredYear':
        this.isOccupied.expiredYear = isfocus;
        break;
      case 'securityCode':
        this.isOccupied.securityCode = isfocus;
        break;
      case 'subscriberEmail':
        this.isOccupied.subscriberEmail = isfocus;
        break;
      case 'subscriberMobile':
        this.isOccupied.subscriberMobile = isfocus;
        break;
      default:
        break;
    }
  }

  onPastecheckCC(ev: string | null | undefined) {
    if (!ev) return;

    if (!/^\s*\d[\d\s]*$/.test(ev)) {
      ev = ev?.replace(/\D/g, '');
    }

    ev = ev
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();

    this.paymentForm.controls.creditCardNumber.setValue(ev);

    if (ev.replaceAll(' ', '').length >= 19) {
      this.monthElement?.nativeElement.focus();
    }
  }

  onAutoFillcheckCC(event: any | null | undefined) {
    if (!event) return;

    setTimeout(() => {
      var ev = event.target?.value;

      if (!ev) return;

      if (!/^\s*\d[\d\s]*$/.test(ev)) {
        ev = ev?.replace(/\D/g, '');
      }

      ev = ev
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();

      this.paymentForm.controls.creditCardNumber.setValue(ev);

      if (ev.replaceAll(' ', '').length >= 19) {
        // this.isOccupied.cardHolderName
        //   ? this.ccnumberElement?.nativeElement.focus()
        //   : this.monthElement?.nativeElement.focus();
      }
    }, 500);
  }

  onAutoFillcheckCH(event: any | null | undefined) {
    if (!event) return;

    setTimeout(() => {
      var ev = event.target?.value;

      if (!ev) return;

      ev = ev.trim();

      this.paymentForm.controls.cardHolderName.setValue(ev);

      if (ev.length >= 40) {
        this.ccnumberElement?.nativeElement.focus();
      }
    }, 500);
  }

  onAutoFillEventMonth(event: any | null | undefined) {
    if (!event) return;

    setTimeout(() => {
      var ev = event.target?.value;

      if (!ev) return;

      if (!/^\s*\d[\d\s]*$/.test(ev)) {
        ev = ev?.replace(/\D/g, '');
      }

      ev = ev.trim();

      // this.paymentForm.controls.expiredMonth.setValue(ev);
      this.paymentForm.patchValue({
        expirationDate: {
          expiredMonth: ev,
        },
      });

      if (ev.length >= 2) {
        this.yearElement?.nativeElement.focus();
      }
    }, 500);
  }

  onAutoFillEventYear(event: any | null | undefined) {
    if (!event) return;

    setTimeout(() => {
      var ev = event.target?.value;

      if (!ev) return;

      if (!/^\s*\d[\d\s]*$/.test(ev)) {
        ev = ev?.replace(/\D/g, '');
      }

      ev = ev.trim();

      // this.paymentForm.controls.expiredYear.setValue(ev);
      this.paymentForm.patchValue({
        expirationDate: {
          expiredYear: ev,
        },
      });

      if (ev.length >= 2) {
        this.cvvElement?.nativeElement.focus();
      }
    }, 500);
  }

  onAutoFillEventCvv(event: any | null | undefined) {
    if (!event) return;

    setTimeout(() => {
      var ev = event.target?.value;

      if (!ev) return;

      if (!/^\s*\d[\d\s]*$/.test(ev)) {
        ev = ev?.replace(/\D/g, '');
      }

      ev = ev.trim();

      this.paymentForm.controls.securityCode.setValue(ev);

      if (ev.length >= 4) {
        this.subscriberEmail?.nativeElement.focus();
      }
    }, 500);
  }

  pay() {
    var holderName = this.paymentForm.controls?.cardHolderName?.value;
    this.paymentForm.controls.cardHolderName.setValue(
      holderName ? holderName.toUpperCase() : null
    );

    var subscriberemail = this.paymentForm.controls?.subscriberEmail?.value;
    var subscribermobile = this.paymentForm.controls?.subscriberMobile?.value;

    // if ((subscriberemail || subscribermobile) && this.paymentForm.value) {
    //   this.paymentForm.value.subscriber = {
    //     emailAddress: subscriberemail?.toUpperCase(),
    //     mobileNumber: subscribermobile?.toUpperCase(),
    //   } as ISubscriberData;
    // }

    if (subscriberemail && this.paymentForm.value) {
      this.paymentForm.value.emailAddress = subscriberemail?.toUpperCase();
    }

    if (this.paymentForm.valid) {
      this.isPaying = true;
      this.submitEvent.emit({
        ...this.paymentForm.value,
        cardHolderName: this.paymentForm.controls.cardHolderName.value || '',
        creditCardNumber:
          this.paymentForm.controls.creditCardNumber.value || '',
        securityCode: this.paymentForm.controls.securityCode.value || '',
        expiredMonth:
          this.paymentForm.get('expirationDate')?.value.expiredMonth || '',
        expiredYear:
          this.paymentForm.get('expirationDate')?.value.expiredYear || '',
        subscriberEmail: this.paymentForm.controls.subscriberEmail.value || '',
        subscriberMobile:
          this.paymentForm.controls.subscriberMobile.value || '',
        showSubscriberEmail:
          this.paymentForm.controls.showSubscriberEmail.value || false,
        subscriber: {
          emailAddress: this.paymentForm.controls.subscriberEmail.value || '',
          mobileNumber: this.paymentForm.controls.mobileNumber.value || '',
        },
      });
    } else {
      this.paymentForm.markAllAsTouched();
    }
  }
}
