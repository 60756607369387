import {Injectable} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {filter} from 'rxjs';

import {SubscriberActions} from '../actions';
import {ChargesReducers} from '../reducers';
import {subscriberSelectors} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionFacade {
  constructor(private readonly _store: Store<ChargesReducers.State>) {}

  selectSubscrition$ = this._store.pipe(
    select(subscriberSelectors.selectSubscrition),
    filter(xx => !!xx)
  );

  selectPaymentId$ = this._store.pipe(
    select(subscriberSelectors.selectPaymentId),
    filter(xx => !!xx)
  );

  selectSubscriberId$ = this._store.pipe(
    select(subscriberSelectors.selectSubscriberId),
    filter(xx => !!xx)
  );

  selectErrorInitialize$ = this._store.pipe(
    select(subscriberSelectors.selectErrorInitialize),
    filter(xx => !!xx)
  );

  getSubscription = (id: any) => {
    this._store.dispatch(SubscriberActions.subscription({id}));
  };

  initialization = (params: {
    id: any;
    externalId: any;
    returnUrl: any;
    cancelUrl: any;
    subscriberId: any;
  }) => {
    this._store.dispatch(
      SubscriberActions.initialization({
        id: params.id,
        externalId: params.externalId,
        returnUrl: params.returnUrl,
        cancelUrl: params.cancelUrl,
        subscriberId: params.subscriberId,
      })
    );
  };

  InitializeSubscription = (id: string) => {
    this._store.dispatch(SubscriberActions.Subcriberinitialization({id}));
  };
}
