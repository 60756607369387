/// <reference types="@angular/localize" />

import {bootstrapApplication} from '@angular/platform-browser';

import {AppComponent} from './app/app.component';
import {appConfig} from './app/app.config';
import {environment} from './environments/environment';

bootstrapApplication(AppComponent, appConfig)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker
        .register('/ngsw-worker.js', {
          updateViaCache: 'none',
        })
        .then(sw => {
          sw.update();
        });
    }
  })
  .catch(err => console.error(err))
  .finally(() => {});
