<div
  class="card"
  [ngClass]="{
    'card--success': status === 'success',
    'card--error': status === 'error',
    'card--loading': status === 'loading'
  }"
>
  <div class="card-icon" *ngIf="status">
    @if(status === 'loading'){
    <img src="assets/images/loader.svg" alt="" />
    }@if(status === 'success'){
    <img src="assets/images/check-circle.svg" alt="" />
    } @if(status === 'error'){
    <img src="assets/images/x-circle.svg" alt="" />
    }
  </div>
  <div class="card-content">
    <div
      class="title"
      [ngClass]="{
        'card--success': status === 'success',
        'card--error': status === 'error',
        'card--loading': status === 'loading'
      }"
    >
      {{ title }}
    </div>
    <div class="description">
      {{ description }}
    </div>
  </div>
  <div class="card-footer" *ngIf="controllers">
    <button
      *ngFor="let controller of controllers"
      nz-button
      (click)="controller.action()"
      [nzType]="controller.type"
    >
      {{ controller.name }}
    </button>
  </div>
</div>
