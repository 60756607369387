import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {URL_PARAMS} from '../constant';
import {luhnCheck} from './luhn.helper';
import {stringToBoolean} from './string-utils';

const cards = [
  '4300002100000012',
  '5351479989003330',
  '4395909989003386',
  '5186190250001017',
  '5172320500098457',
];

export const checkIfCardIsTest = (card: string): boolean => {
  const cardNormalized = card.replace(/\s/g, '').trim();
  return cards.includes(cardNormalized);
};

export function luhnValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const isValid = luhnCheck(control.value);

    return !isValid ? {luhnCheck: true} : null;
  };
}

export function cardTestValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const urlParams = new URLSearchParams(window.location.search);

    const param = urlParams.get(URL_PARAMS.LIVE_MODE);
    const livemode = param ? stringToBoolean(param) : true;

    const isValid = !!livemode ? true : checkIfCardIsTest(control.value);

    return !isValid ? {checkIfCardIsTest: true} : null;
  };
}

export function CVVunipayValidation(): any {
  return (form: FormGroup): ValidationErrors | null => {
    const UNIPAY_REGEX = '^(62|81)';
    const cardNumber = form.get('creditCardNumber')?.value;
    const cvv = form.get('securityCode')?.value;

    if (!cardNumber?.match(UNIPAY_REGEX) && cvv?.length <= 1) {
      return {cvvUnipayRequired: true};
    } else {
      return null;
    }
  };
}
