import {CommonModule} from '@angular/common';
import {AfterViewInit, Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTES_KEYS, URL_PARAMS} from '@constant/index';
import {Settings} from '@models/index';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {ChargesService} from '@services/charges.service';
import {ErrorService} from '@services/error.service';
import {Frame3dsService} from '@services/frame3ds.service';
import {SettingsService} from '@services/settings.service';
import {ChargesFacade} from '@store/facade/charges-facade.service';
import {PaymentReducers} from '@store/reducers';
import {paramsToLower} from '@utils/query-params-utils';
import {Observable, combineLatest} from 'rxjs';
import {StatusCardComponent} from '../../components/status-card/status-card.component';

@UntilDestroy()
@Component({
  selector: 'app-bkn-close-transaction',
  standalone: true,
  imports: [CommonModule, TranslateModule, StatusCardComponent],
  template: `
    <app-status-card
      *ngIf="!embedRequired"
      [icon]="'loading'"
      [status]="'loading'"
      [title]="'CARDS_STATUS.LOADING.Title' | translate"
      [description]="'CARDS_STATUS.LOADING.Description' | translate"
    >
    </app-status-card>
  `,
  styles:
    ':host { display: flex; justify-content: center; align-items: center; height: 100%; }',
})
export class BknCloseTransactionComponent implements OnInit, AfterViewInit {
  configuration: Observable<Settings>;

  _configurationService = inject(SettingsService);
  _frameService = inject(Frame3dsService);
  _chagesService = inject(ChargesService);
  _chargeFacade = inject(ChargesFacade);
  _router = inject(Router);
  _store = inject(Store<PaymentReducers.State>);
  _errorService = inject(ErrorService);
  _activatedRouter = inject(ActivatedRoute);
  embedRequired: boolean = false;

  constructor() {
    this.configuration = this._configurationService.Get();

    combineLatest([
      this._chargeFacade.Authorize$.pipe(untilDestroyed(this)),
      this._activatedRouter.queryParams,
    ]).subscribe(res => {
      if (res[0]?.['success'] && !res[0]['returnUrl']) {
        // if the transaction is successful & the paywal is in embedded mode
        if (res.length > 1 && res[1]?.['embedRequired'] === 'true') {
          // embed required is true, notify parent of payment success
          window.parent.postMessage({type: 'PAYMENT_SUCCESS', data: res}, '*');
          return;
        } else {
          // if paywall is standalone  and not embedded, route the app to thank you page
          this._router.navigate([ROUTES_KEYS.NETS.thankyou], {
            queryParams: {
              paymentId: res[1]?.['paymentId'],
              lang: res[1]?.['lang'],
              livemode: res[1]?.['livemode'],
            },
          });
        }
      } else if (!res[0]?.['success']) {
        // if the transaction is unsuccessful, and paywall is in embedded mode
        if (res.length > 1 && res[1]?.['embedRequired'] === 'true') {
          // embed required is true, notify parent of payment failure
          window.parent.postMessage({type: 'PAYMENT_FAILURE', data: res}, '*');
          return;
        } else {
          // if paywall is standalone and not embedded, route the app to error page
          this._errorService.SetMessage(res[0]?.['gatewayCode']);

          this._router.navigate([ROUTES_KEYS.ERROR], {
            queryParams: {
              paymentId: res[1]?.['paymentId'],
              lang: res[1]?.['lang'],
              livemode: res[1]?.['livemode'],
            },
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this._chargeFacade.resetAuthorize();
    // get the query params from the url
    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(item => {
        const res = paramsToLower(item);
        // set the embedRequired flag value from the url params
        this.embedRequired = res[URL_PARAMS.EMBEDREQUIRED];
      });
  }

  ngAfterViewInit(): void {
    //call last authorize to procced to payment
    const request = this._chagesService.GetAuthorizeBknFromSession();

    if (request) this._chargeFacade.authorizeBkn(request);
    //this._router.navigate([ROUTES.ERROR]);
    else this._frameService.iframeCloseEvent.emit();
  }
}
