import {CommonModule, DOCUMENT} from '@angular/common';
import {Component, Inject, inject} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChargesFacade} from '@store/facade/charges-facade.service';
import {Observable} from 'rxjs';

import {ActivatedRoute} from '@angular/router';
import {LOCALSTORAGE_KEYS} from '@constant/localstorage-keys';
import {URL_PARAMS} from '@constant/urlparams-keys';
import {ChargesRequestDto, PaymentDto, SubmerchantDto} from '@models/index';
import {TranslateModule} from '@ngx-translate/core';
import {PaymentService} from '@services/payment.service';
import {paramsToLower} from '@utils/query-params-utils';
import {NzIconModule} from 'ng-zorro-antd/icon';
@UntilDestroy()
@Component({
  selector: 'app-georgian-payment',
  templateUrl: './georgian-payment.component.html',
  styles: [
    `
      .card {
        padding: 24px;
      }
    `,
    `
      .loading-description {
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        margin: 0;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, NzIconModule, TranslateModule],
})
export class GeorgianPaymentComponent {
  subMerchant?: Observable<SubmerchantDto | null | undefined>;
  payment$?: Observable<PaymentDto | null | undefined>;
  redirectUri: string = '#';

  sanitizer = inject(DomSanitizer);
  _chargesFacade = inject(ChargesFacade);
  _paymentService = inject(PaymentService);
  _activatedRouter = inject(ActivatedRoute);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this._chargesFacade.Charges$.subscribe(charge => {
      if (charge?.id)
        localStorage.setItem(LOCALSTORAGE_KEYS.CHARGE_ID, charge?.id);
      if (charge?.redirectUri) {
        this.redirectUri = charge.redirectUri;

        // DIRECT REDIRECT
        setTimeout(() => {
          this.document.location.href = this.redirectUri;
        }, 2000);
      }
    });
  }

  ngOnInit(): void {
    this.reset();

    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(item => {
        const res = paramsToLower(item);

        const paymentId = res[URL_PARAMS.PAYMENT_ID];

        this._paymentService.SetGatewayPaymentId('gc-ge');
        const paymentGatewayId = 'gc-ge';

        if (paymentId && paymentGatewayId) {
          this._chargesFacade.chargesCreate({
            paymentGatewayId,
            paymentId,
          } as ChargesRequestDto);
        }
      });
  }

  private reset() {
    this._chargesFacade.reset();
  }
}
