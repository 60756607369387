export const ReplaceUri = (uri: string, paymentId: string) => {
  // var uri="http://www.pippo.com?paymentId={paymentId}"
  var searchMask = '{paymentId}';
  var regEx = new RegExp(searchMask, 'ig');

  return uri.replace(regEx, paymentId);
};

export const IsJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const stringToBoolean = (str: string | null | undefined) => {
  if (!str) {
    return false;
  }
  if (typeof str === 'string') {
    return !['0', 'false', 'no', 'n', 'null', 'undefined', 'nil'].includes(
      str.toLowerCase().trim()
    );
  }
  return Boolean(str);
};
