import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromTheme from '../reducers/theme.reducer';

export const ThemeFeatureSelector = createFeatureSelector<fromTheme.State>(
  fromTheme.themeFeatureKey
);

export const selectTheme = createSelector(ThemeFeatureSelector, state => {
  return state?.theme;
});
