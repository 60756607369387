import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {ResponseLink} from '../../models';
import {PrimitiveSuccess} from '../../models/primitive-vm';
import * as PaymentActions from '../actions/payment.action';
import {PaymentDto, SubmerchantDto} from './../../models/data-contracts';

export const paymentFeatureKey = 'payment';

export interface State extends EntityState<SubmerchantDto> {
  error?: any;
  errorCreate?: any;
  errorSubmerchant?: any;
  errorValidate?: any;
  submerchant: SubmerchantDto | undefined;
  payment: PaymentDto | undefined;
  paymentCreate: ResponseLink | undefined;
  validate: PrimitiveSuccess<boolean> | undefined;
}

export const adapter: EntityAdapter<SubmerchantDto> =
  createEntityAdapter<SubmerchantDto>({
    // sortComparer: (a: PaymentGatewaysDto, b: PaymentGatewaysDto) =>
    //   a.isCLosed === b.isCLosed
    //     ? 0
    //     : a.isCLosed === false || b.isCLosed === false
    //     ? 1
    //     : -1,
    // selectId: (a: Trade) => a.idTriangleFull,
  });

export const initialState: State = adapter.getInitialState({
  entities: [],
  error: undefined,
  errorSubmerchant: undefined,
  errorValidate: undefined,
  errorCreate: undefined,
  submerchant: undefined,
  payment: undefined,
  paymentCreate: undefined,
  validate: undefined,
  ids: [],
});

export const paymentSubmerchantReducers = createReducer(
  initialState,

  on(PaymentActions.submerchantSuccess, (state, {submerchant}) => {
    return {
      ...state,
      submerchant,
      errorSubmerchant: undefined,
    };
  }),
  on(PaymentActions.submerchantError, (state, {error}) => ({
    ...state,
    errorSubmerchant: error,
  })),
  on(PaymentActions.submerchantReset, state => ({
    ...state,
    errorSubmerchant: undefined,
    submerchant: undefined,
  })),
  on(PaymentActions.paymentSuccess, (state, {payment}) => {
    return {
      ...state,
      error: undefined,
      payment,
    };
  }),
  on(PaymentActions.paymentError, (state, {error}) => ({
    ...state,
    error: error,
  })),
  on(PaymentActions.paymentReset, state => ({
    ...state,
    payment: undefined,
    error: undefined,
  })),
  on(PaymentActions.createPaymentLink, state => {
    return {
      ...state,
      errorCreate: undefined,
      paymentCreate: undefined,
    };
  }),
  on(PaymentActions.createPaymentLinkSuccess, (state, {payment}) => {
    return {
      ...state,
      errorCreate: undefined,
      paymentCreate: payment,
    };
  }),
  on(PaymentActions.createPaymentLinkError, (state, {error}) => {
    return {
      ...state,
      errorCreate: error,
    };
  }),
  on(PaymentActions.createPaymentLinkReset, state => {
    return {
      ...state,
      paymentCreate: undefined,
      errorCreate: undefined,
    };
  }),
  on(PaymentActions.validateSuccess, (state, {response}) => {
    return {
      ...state,
      validate: response,
    };
  }),
  on(PaymentActions.submerchantError, (state, {error}) => ({
    ...state,
    errorValidate: error,
    validate: undefined,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return paymentSubmerchantReducers(state, action);
}

export const {selectAll, selectIds, selectEntities, selectTotal} =
  adapter.getSelectors();
