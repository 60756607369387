import {Injectable} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {filter} from 'rxjs';
import {AuthorizeBknRequestDto, ChargesRequestDto} from '../../models';
import {
  authorizeBkn,
  authorizeBknReset,
  chargesCreate,
  chargesCreateReset,
} from '../actions/charges.action';
import {ChargesReducers} from '../reducers';
import {chargesSelectors} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class ChargesFacade {
  constructor(private readonly _store: Store<ChargesReducers.State>) {}

  Charges$ = this._store.pipe(
    select(chargesSelectors.selectChargeCreateSuccess),
    filter(item => !!item)
  );

  Authorize$ = this._store.pipe(
    select(chargesSelectors.selectAuthorizeBknSuccess),
    filter(item => !!item)
  );

  //ERROR ON BKN AUTHORIZATION
  AuthorizeError$ = this._store.pipe(
    select(chargesSelectors.selectErrorAuthorizeBkn),
    filter(item => !!item)
  );

  chargesCreate = (charges: ChargesRequestDto) => {
    this._store.dispatch(chargesCreate({charges}));
  };

  reset = () => {
    this._store.dispatch(chargesCreateReset());
  };

  resetAuthorize = () => {
    this._store.dispatch(authorizeBknReset());
  };

  authorizeBkn = (request: AuthorizeBknRequestDto) => {
    this._store.dispatch(authorizeBkn({request}));
  };
}
