import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {PaymentService} from '../../services/payment.service';
import * as PaymentActions from '../actions/payment.action';
@Injectable({
  providedIn: 'root',
})
export class PaymentEffects {
  constructor(
    private _actions$: Actions,
    private _paymentService: PaymentService
  ) {}

  submerchant$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PaymentActions.submerchant),
      concatMap(xx =>
        this._paymentService.GetSubMerchantFromLocal(xx.id).pipe(
          map(submerchant => PaymentActions.submerchantSuccess({submerchant})),
          catchError(errorMessage => {
            return of(PaymentActions.submerchantError(errorMessage));
          })
        )
      )
    )
  );

  payment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PaymentActions.payment),
      concatMap(xx =>
        this._paymentService.Find(xx.paymentId).pipe(
          map(payment => PaymentActions.paymentSuccess({payment})),
          catchError(errorMessage =>
            of(PaymentActions.paymentError(errorMessage))
          )
        )
      )
    )
  );

  createPaymentLink$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PaymentActions.createPaymentLink),
      concatMap(xx =>
        this._paymentService.PaymentLink(xx.idLink).pipe(
          map(payment => PaymentActions.createPaymentLinkSuccess({payment})),
          catchError(errorMessage =>
            of(PaymentActions.createPaymentLinkError(errorMessage))
          )
        )
      )
    )
  );

  validate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PaymentActions.validate),
      concatMap(xx =>
        this._paymentService.Validate(xx.id).pipe(
          map(response => PaymentActions.validateSuccess({response})),
          catchError(errorMessage =>
            of(PaymentActions.validateError(errorMessage))
          )
        )
      )
    )
  );
}
