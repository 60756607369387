import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {NzSpinModule} from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-subscription-initialization',
  template: `
    <nz-spin [nzSpinning]="isLoading">
      <div class="row pt-5 justify-content-center min-vh-100"></div>
    </nz-spin>
  `,
  standalone: true,
  imports: [CommonModule, NzSpinModule],
})
export class SubscriptionInitializationComponent {
  isLoading: boolean = true;
}
