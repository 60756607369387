import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {loadScript, PayPalNamespace} from '@paypal/paypal-js';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayPalService {
  isloaded$: Subject<PayPalNamespace | null | undefined> = new Subject();
  _isLoaded: PayPalNamespace | null | undefined = undefined;
  constructor() {}

  load(currency: string, merchantId: string) {
    return new Promise(resolve => {
      // if (!this._isAlreadyLoaded) {
      loadScript({
        'client-id': environment.PAY_PAYL_ID,
        currency: currency,
        'disable-funding': 'credit,card,bancontact,sofort,mybank',
        'enable-funding': 'venmo,paylater',
        'merchant-id': merchantId,
      })
        .then(paypal => {
          // start to use the PayPal JS SDK script
          this.isloaded$.next(paypal);
          this._isLoaded = paypal;
          this.isloaded$.complete();
          resolve(paypal);
        })
        .catch(() => {
          this.isloaded$.next(null);
          this._isLoaded = null;
          this.isloaded$.complete();
          resolve(null);
        });
    });
  }

  isLoaded(): PayPalNamespace | null | undefined {
    return this._isLoaded;
  }
}
