import {registerLocaleData} from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideRouter} from '@angular/router';
import {provideServiceWorker} from '@angular/service-worker';
import {LOCALSTORAGE_KEYS} from '@constant/index';
import {errorInterceptor} from '@interceptors/error.interceptor';
import {EffectsModule} from '@ngrx/effects';
import {provideStore} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {LocateService} from '@services/locate.service';
import {SettingsService} from '@services/settings.service';
import {ChargesEffects} from '@store/effects/charges.effect';
import {PaymentEffects} from '@store/effects/payment.effect';
import {ThemeEffects} from '@store/effects/theme.effect';
import {SubscriptionEffects} from '@store/index';
import {
  ChargesReducers,
  LoadingReducers,
  PaymentReducers,
  SubscriptionReducers,
  ThemeReducers,
} from '@store/reducers';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {NZ_I18N} from 'ng-zorro-antd/i18n';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {startupServiceLoad} from './app.initializer';
import {routes} from './app.routes';

registerLocaleData(en);
registerLocaleData(it);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        const language_from_storage = localStorage.getItem(
          LOCALSTORAGE_KEYS.LANG
        );

        // switch (language_from_storage) {
        //   case 'en':
        //     return en_US;
        //   case 'it':
        //     return it_IT;
        //   /** keep the same with angular.json/i18n/locales configuration **/
        //   case 'fr':
        //     return fr_FR;
        //   case 'ar':
        //     return ar_EG;
        //   case 'ka':
        //     return ka_GE;
        //   default:
        //     return en_US;
        // }
        return language_from_storage;
      },
      deps: [LOCALE_ID],
    },
    importProvidersFrom([
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      EffectsModule.forRoot([
        PaymentEffects,
        ChargesEffects,
        ThemeEffects,
        SubscriptionEffects,
      ]),
      NgCircleProgressModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        isolate: true,
      }),
    ]),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([errorInterceptor])),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideStore({
      [PaymentReducers.paymentFeatureKey]: PaymentReducers.reducer,
      [LoadingReducers.loaderFeatureKey]: LoadingReducers.reducer,
      [ChargesReducers.chargesFeatureKey]: ChargesReducers.reducer,
      [SubscriptionReducers.subscribersFeatureKey]:
        SubscriptionReducers.reducer,
      [ThemeReducers.themeFeatureKey]: ThemeReducers.reducer,
    }),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true,
      trace: false,
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceLoad,
      multi: true,
      deps: [SettingsService, TranslateService, LocateService],
    },
  ],
};

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/', suffix: '.json'},
    {prefix: './assets/i18n/', suffix: '.json'},
    {prefix: './assets/i18n/', suffix: '.json'},
  ]);
}
