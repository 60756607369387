import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Personalization, Settings} from '../models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private _http: HttpClient) {}

  Get() {
    return this._http.get<Settings>('assets/json/settings.json');
  }

  SetColors(conf: Settings) {
    // document.documentElement.style.setProperty('--p-color', conf.primarycolor);
    // document.documentElement.style.setProperty(
    //   '--p-color-btn',
    //   conf.primarycolor
    // );
    // document.documentElement.style.setProperty(
    //   '--s-color',
    //   conf.secondarycolor
    // );
    // document.documentElement.style.setProperty(
    //   '--s-color-btn',
    //   conf.secondarycolor
    // );
    // document.documentElement.style.setProperty('--t-color', conf.thirdcolor);
    // document.documentElement.style.setProperty('--h-color', conf.headercolor);
    // document.documentElement.style.setProperty(
    //   '--h-bg-color',
    //   conf.headerbgcolor
    // );
    // document.documentElement.style.setProperty(
    //   '--lp-color',
    //   conf.lightprimarycolor
    // );
  }

  SetPrimaryColor(conf: Settings) {
    // document.documentElement.style.setProperty('--p-color', conf.primarycolor);
  }

  SetSecondaryColor(conf: Settings) {
    // document.documentElement.style.setProperty(
    //   '--s-color',
    //   conf.secondarycolor
    // );
  }

  SetThirdColor(conf: Settings) {
    // document.documentElement.style.setProperty('--t-color', conf.thirdcolor);
  }

  SetBtnColors(personalization?: Personalization) {
    // if (
    //   personalization?.payButtonColor &&
    //   personalization?.payButtonTextColor
    // ) {
    //   document.documentElement.style.setProperty(
    //     '--p-color-btn',
    //     personalization.payButtonColor
    //   );
    //   document.documentElement.style.setProperty(
    //     '--s-color-btn',
    //     personalization.payButtonTextColor
    //   );
    // }
  }

  SetFavicon(conf: Settings) {
    let doc = document.getElementById('appFavicon');
    doc?.setAttribute('href', conf.faviconHref);
  }
}
