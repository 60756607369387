import {Injectable} from '@angular/core';
import {ErrorHandling} from '../utils';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private _error: string = '';
  private default_key = 'CARDS_STATUS.ERROR.Description';

  constructor(private readonly _errorHandling: ErrorHandling) {}

  Get() {
    return this._error;
  }

  Set(error: any) {
    this._error = error
      ? this._errorHandling.HandleError(error)
      : this._errorHandling.HandleError(this.default_key);
  }

  SetMessage(error: any) {
    this._error = error;
  }

  Clear() {
    this._error = '';
  }
}
