import {environment} from '@env/environment';

export interface Scripts {
  name: string;
  src: string;
  crossOriginCredentials?: boolean;
}
export const ScriptStore: Scripts[] = [
  {
    name: 'three-ds',
    src: 'https://test-nbe.gateway.mastercard.com/static/threeDS/1.3.0/three-ds.min.js',
  },
  {
    name: 'sessionjs',
    src: `https://test-nbe.gateway.mastercard.com/form/version/${environment.MASTERCARD_API_VERSION}/merchant/__ID__MERCHANT__/session.js`,
  },
  {
    name: 'nethonejs',
    src: `https://d354c9v5bptm0r.cloudfront.net/s/287436/dAtCKS.js`,
    crossOriginCredentials: true,
  },
];
