export const URL_PARAMS = {
  LIVE_MODE: 'livemode',
  PAYMENT_ID: 'paymentid',
  LANG: 'lang',
  PAYMENT_LINK_ID: 'paymentlinkid',
  SUBSCRIPTION_ID: 'subscriptionid',
  EXTERNAL_ID: 'externalid',
  RETURN_URL: 'returnurl',
  CANCEL_URL: 'cancelurl',
  SUBSCRIBER_ID: 'subscriberid',
};
