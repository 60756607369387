import {Injectable} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {filter, map} from 'rxjs';
import {PAYMENT_GATEWAY_STATES, PAYMENT_IDS} from '../../constant';
import {PaymentActions} from '../actions';
import {PaymentReducers} from '../reducers';
import {paymentSelectors} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class PaymentFacade {
  PaymentLink$ = this._store.pipe(
    select(paymentSelectors.selectFirstOrDefaulPaymentCreated),
    filter(item => !!item)
  );

  PaymenGateways$ = this._store.pipe(
    select(paymentSelectors.selectAllPaymentGateways),
    filter(item => !!item)
  );

  Cards$ = this._store.pipe(
    select(paymentSelectors.selectAllPaymentGateways),
    filter(
      x =>
        !!x &&
        !!x.filter(
          p =>
            p.id != PAYMENT_IDS.PAYPAL ||
            (p.id == PAYMENT_IDS.PAYPAL &&
              p.active &&
              p.state == PAYMENT_GATEWAY_STATES.CONNECTED)
        )
    ),
    map(gates => {
      return gates
        ? gates.map(y => {
            return {
              id: y.id,
              value: false,
              route: y.route,
              paymentId: y.paymentId,
            } as {
              id: string | undefined;
              value: boolean;
              route: string;
              paymentId: string;
            };
          })
        : [];
    })
  );

  constructor(private readonly _store: Store<PaymentReducers.State>) {}

  CreatePaymentLink = (idLink: string) =>
    this._store.dispatch(PaymentActions.createPaymentLink({idLink}));

  CreatePayment = (paymentId: string) =>
    this._store.dispatch(PaymentActions.payment({paymentId}));
}
