export const ROUTES_KEYS = {
  HOME: `/full/home`,
  HOME_VOUCHER: `/full/home-voucher`,
  HOME_WITH_PAYMENT: (paymentId: string) => `/full/home?paymentId=${paymentId}`,
  HOME_VOUCHER_WITH_PAYMENT: (paymentId: string) =>
    `/full/home-voucher?paymentId=${paymentId}`,
  NETS: {
    payment: '/full/home',
    thankyou: '/full/thankyou',
    otp: '/full/3ds2',
  },
  VOUCHER: {
    payment: '/full/voucher/payment',
    thankyou: '/full/voucher/thankyou',
    otp: '/full/voucher/3ds2',
  },
  PAYMENT: {
    GATEWAY: (route: string) => `/full/home`,
    CLASSIC: `/full/home`,
  },
  PAYMENT_VOUCHER: {
    GATEWAY: `/full/voucher/payment`,
  },
  PAYPAL: {
    thankyou: '/full/thankyou',
  },
  SUBSCRIPTION: {
    HOME: '/home/subscription',
    INITIALIZE: '/home/subscribers',
  },
  ERROR: '/full/error-internal',
  ERROR_VOUCHER: '/full/error-voucher',
  GCGE_PAYMENT: '/full/gcge',
};
