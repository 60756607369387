import {Injectable} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {filter} from 'rxjs';
import * as ThemeActions from '../actions/theme.action';
import {ThemeReducers} from '../reducers';
import {themeSelector} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class ThemeFacade {
  constructor(private readonly _store: Store<ThemeReducers.State>) {}

  theme$ = this._store.pipe(
    select(themeSelector.selectTheme),
    filter(item => !!item)
  );

  getTheme = (id: string) => {
    this._store.dispatch(ThemeActions.getMerchantTheme({id}));
  };
}
