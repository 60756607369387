import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'checkCCN',
  standalone: true,
})
export class CheckCCNPipe implements PipeTransform {
  transform(
    value: string | null | undefined,
    ...args: unknown[]
  ): string | null | undefined {
    if (!value) return value;

    if (!/^\s*\d[\d\s]*$/.test(value)) {
      value = value.replace(/\D/g, '');
    }

    return value;
  }
}
