import {Injectable} from '@angular/core';
import {ScriptLoader} from '../models/ScriptLoader';
import {ScriptStore} from '../utils/script.store';

declare var document: any;

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        crossOriginCredetials: script.crossOriginCredetials,
      };
    });
  }

  load(scripts: ScriptLoader[]) {
    var promises: any[] = [];
    scripts.forEach(script =>
      promises.push(
        this.loadScript(
          script.name,
          script.marchantId,
          script.crossOriginCredentials
        )
      )
    );
    return Promise.all(promises);
  }

  loadScript(
    name: string,
    merchantId: string,
    crossOriginCredentials?: boolean
  ) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        console.log('Already Loaded');

        resolve({script: name, loaded: true, status: 'Already Loaded'});
      } else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        if (crossOriginCredentials) {
          script.crossOrigin = 'use-credentials';
          // script.crossorigin = 'use-credentials';
          // script.setAttribute('crossorigin', 'use-credentials');
        }
        script.src = this.scripts[name].src;
        script.src = (script.src as string).replace(
          '__ID__MERCHANT__',
          merchantId
        );
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) =>
          resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
